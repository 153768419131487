
$bg1: #292929;
$bg2: #343434;
$w40: rgba(#fff2e1,1);
$w60: rgba(#fff2e1,.6);
$apple: #dceeac;
$corp: #c2feee;
$corp2: #f6d999;
$pre: #ded8bb;
$pre2: #d9c282;
$bp1: #332e3d;
$bp2: #222027;
.bg-b1 {
  background-color: $bg1; }
.bg-b2 {
  background-color: $bg2; }
.bg-apple {
  background-color: $apple; }
.text-bg1 {
  color: $bg1; }
.text-bg2 {
  color: $bg2; }
.text-apple {
  color: $apple; }

.rounded-lg {
  border-radius: 15px; }
.border-green {
  border: .5px solid $green; }
.border-green-dark {
  border: .5px solid $green-dark; }

.btn-copy {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: $bg2 url('../img/dashboard/copy.svg') center / 26px no-repeat;
  &:active {
    background-color: $apple; } }




.dashpage {
  background-color: $bg1;
  min-height: 100vh;
  display: flex;
  color: $w60;
  font-family: $roboto;
  overflow-x: hidden;
  overflow-y: auto;
  h1,h2,h3,h4,h5,h6, strong {
    font-family: $titleDash; }
  .slick-dots {
    button {
      background-color: $apple; } }

  main {
    min-width: 210px;
    border-right: .2px solid $green-dark;
    position: sticky;
    top: 0; }
  aside {
    scroll-behavior: smooth;
    width: 100%;
    background: $bg2;
    overflow-y: auto;
    height:  100vh;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    &::-webkit-scrollbar-track {
      background-color: $bg1;
      border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
      background-color: $apple;
      border-radius: 10px; } }

  .form-switch {
    display: flex;
    justify-content: flex-start;
    .form-check-input {
      width: 90px;
      height: 35px;
      box-shadow: none;
      &:checked {
        filter: hue-rotate(209deg); }
      &:focus {
        outline: none;
        box-shadow: 0; } } }


  @media screen and ( max-width: 1000px ) {
    main {
      min-width: 60px;
      width: 60px;
      max-width: 60px; } }
  @media screen and ( max-width: 450px ) {
    .form-switch {
      justify-content: center; }
    aside {
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px; } } } }







.logoDash {
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  .logo1 {
    width: 90px; }
  .logo2 {
    display: none; }
  @media screen and ( max-width: 1000px ) {
    padding: 12px;
    button {
      display: none; }
    .logo1 {
      display: none; }
    .logo2 {
      display: block;
      padding: 0px; } } }

.dropUser {
  .btn {
    display: inline-flex;
    align-items: center;
    color: white;
    background: none;
    border: none;
    border-left: .5px solid rgba($green,.4);
    border-radius: 0;
    padding-left: 20px;
    margin-left: 15px;
    font-size: 15px;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      margin-right: 8px;
      background: url('../img/dashboard/user_drop.svg') center / contain no-repeat; } }
  .dropdown-menu {
    width: 280px;
    background-color: $bg1;
    border: .5px solid $green;
    padding: 0;
    button, a {
      width: 100%;
      display: block;
      text-align: center;
      padding: 10px 20px;
      background-color: transparent;
      color: white;
      border-bottom: .5px solid $green;
      &:nth-last-child(1) {
        border: none; }
      &:hover {
        background-color: $bg2;
        color: $apple; } } } }




.headerDashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $bg1;
  padding: 10px 20px;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: .2px solid $green-dark;
  div {
    display: flex;
    align-items: center; }
  @media screen and ( max-width: 745px ) {
    display: none; } }

.sesion {
  background-color: $bg2;
  color: $w60;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 0 10px 0 4px;
  border-radius: 5px;
  &::before {
    content: '';
    width: 25px;
    height: 25px;
    background: url('../img/dashboard/sesion_off.svg') center / contain no-repeat;
    margin-right: 7px;
    transition: all .3s; }
  &:hover {
    color: $bg1;
    background-color: $green-light;
    &::before {
      background: url('../img/dashboard/sesion_on.svg') center / contain no-repeat; } } }

.headerMobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  background: $bg1;
  padding: 10px;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: .5px solid $green-dark;
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis; }
  button {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    background-color: $bg1;
    border-radius: 6px;
    border: none;
    padding: 10px 0px;

    div {
      pointer-events: none;
      width: 25px;
      height: 1px;
      background-color: $green-light;
      &:nth-child(1) {
        width: 18px; }
      &:nth-child(2) {
        width: 22px; } } }
  @media screen and ( max-width: 745px ) {
    display: flex; } }






.dropAdmin {
  button.dropdown-toggle {
    background-color: $bg2;
    color: $w60;
    height: 32px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 0;
    border: none;
    font-size: 15px;
    &::after {
      content: '';
      width: 25px;
      height: 25px;
      background: url('../img/dashboard/dropdown.svg') center / contain no-repeat;
      margin-left: 7px;
      transition: all .3s;
      border: none !important; } }
  ul.dropdown-menu {
    background-color: $bg2;
    padding: 0;
    overflow: hidden;
    border: .5px solid $w40;
    width: 280px;
    a, button {
      color: $w60;
      display: block;
      border-bottom: .5px solid $w60;
      padding: 10px 20px;
      width: 100%;
      text-align: center;
      &:hover {
        background-color: $green-dark;
        color: white; }
      &:nth-last-child(1) {
        border: none; } } } }

.furtherInfo {
  width: 27px;
  height: 27px;
  border: none;
  background: transparent  url('../img/dashboard/point_information.svg') center / contain no-repeat;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  @media screen and ( max-width: 768px ) {
    width: 31px;
    height: 31px; } }



.tabLateral {
  min-height: calc( 100vh - 50px );
  overflow-x: auto;
  padding-top: 17px;
  padding-left: 15px;
  display: block;
  position: sticky;
  top: 40px;
  border-bottom: none;
  li {
    display: block;
    width: 100%;
    height: 40px;
    &.disabled {
      opacity: .4;
      pointer-events: none; }
    .nav-link {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      color: $w60;
      background-color: transparent;
      border: none;
      border-radius: 30px 0 0 30px;
      position: relative;
      .point {
        max-width: 40px;
        min-width: 22px;
        width: auto;
        font-size: 13px;
        padding: 1px 6px;
        border-radius: 15px;
        background-color: $corp;
        color: $bp1;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        animation: pulseKitchen  4s infinite;
        @media screen and ( max-width: 768px ) {
          top: 6px;
          transform: none;
          right: 6px;
          font-size: 0px;
          width: 11px;
          height: 11px;
          min-width: 11px;
          max-width: 11px;
          padding: 0; } }

      &:hover {
        color: white;
        &::before {
          opacity: 1; } }
      &.active {
        background-color: $bg2;
        color: $w40;
        border-radius: 30px 0 0 30px;
        &::before {
          opacity: 1; } }
      &::before {
        content: '';
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        margin-right: 10px;
        opacity: .6; }
      &.novedades {
        &::before {
          background: url('../img/dashboard/icon_ad.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_ad_green.svg') center / contain no-repeat; } } }
      &.plantilla {
        &::before {
          background: url('../img/dashboard/icon_themes.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_themes_green.svg') center / contain no-repeat; } } }
      &.productos {
        &::before {
          background: url('../img/dashboard/icon_product.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_product_green.svg') center / contain no-repeat; } } }
      &.reportes {
        &::before {
          background: url('../img/dashboard/icon_reports.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_reports_green.svg') center / contain no-repeat; } } }
      &.cocina {
        &::before {
          background: url('../img/dashboard/icon_kitchen.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_kitchen_green.svg') center / contain no-repeat; } } }
      &.qr {
        &::before {
          background: url('../img/dashboard/icon_qr.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_qr_green.svg') center / contain no-repeat; } } }
      &.payments {
        &::before {
          background: url('../img/dashboard/icon_payment.svg') center / 25px no-repeat !important; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_payment_green.svg') center / contain no-repeat; } } }
      &.personal {
        &::before {
          background: url('../img/dashboard/icon_personal.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_personal_green.svg') center / contain no-repeat; } } }
      &.libreta {
        &::before {
          background: url('../img/dashboard/icon_note.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_note_green.svg') center / contain no-repeat; } } }
      &.configuracion {
        &::before {
          background: url('../img/dashboard/icon_settings.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_settings_green.svg') center / contain no-repeat; } } }
      &.mesas {
        &::before {
          background: url('../img/dashboard/icon_table.svg') center / contain no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_table_green.svg') center / contain no-repeat; } } }
      &.calculadora {
        &::before {
          background: url('../img/dashboard/icon_calculator.svg') center / 25px no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_calculator_green.svg') center / 25px no-repeat; } } }
      &.post {
        &::before {
          background: url('../img/dashboard/icon_post.svg') center / 25px no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_post_green.svg') center / 25px no-repeat; } } }
      &.state {
        &::before {
          background: url('../img/dashboard/icon_state.svg') center / 25px no-repeat; }
        &.active {
          &::before {
            background: url('../img/dashboard/icon_state_green.svg') center / 25px no-repeat; } } } } }
  @media screen and ( max-width: 1000px ) {
    padding: 5px;
    top: 55px;
    li {
      height: 50px;
      .nav-link {
        border-radius: 5px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        &::before {
          margin: 0;
          background-size: 31px !important; }
        &.active {
          background-color: $green2;
          border-radius: 5px;
          border: none;
          &::before {
            filter: brightness(10); } }

        span {
          display: none; } } } } }


@keyframes pulseKitchen {
  0% {
    box-shadow: 0 0 0px 0 rgba($corp, 1); }
  35% {
    box-shadow: 0 0 0px 15px rgba($corp, 0); }
  100% {
    box-shadow: 0 0 0px 0 rgba($corp, 0); } }



.btn-green-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $bg1;
  background-color: $green-dark;
  border-radius: 30px;
  padding: 7px;
  height: 55px;
  position: relative;
  &:hover {
    background-color: $green;
    color: $bg1; }
  &.back {
    &::before {
      content: '';
      width: 25px;
      height: 25px;
      margin-right: 10px;
      background: url('../img/dashboard/arrow_prev.svg') center / 25px no-repeat; }
    &:hover {
      background-color: $green-dark2;
      color: white; } } }
.btn-line-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange-light;
  background-color: transparent;
  border-radius: 30px;
  border: .5px solid $orange-light;
  padding: 7px;
  height: 55px;
  &:hover {
    background-color: $orange-light;
    color: $bg1; } }



.plegableMenu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: transparent;
  display: flex;
  overflow: hidden;
  transition: all .1s;
  pointer-events: none;
  .overlay {
    width: 100%;
    height: 100vh; }
  .content {
    background-color: $bg1;
    padding: 40px;
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    transition: all .2s;
    transform: translateX(100%);
    .linkPane {
      width: 100%;
      display: block;
      padding: 15px 0;
      color: white;
      text-align: right;
      border-bottom: .5px solid white;
      &:hover {
        border-bottom: .5px solid $apple;
        color: $apple; } }
    .sesion {
      width: 100%;
      margin: 0;
      color: white;
      height: 40px;
      margin-top: 50px; } }
  &.active {
    pointer-events: auto;
    background-color: rgba(black,.6);
    .content {
      transform: translateX(0%); } } }


.inputGreen {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: $apple;
  border: none;
  color: $bg1;
  padding: 20px; }



.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      background-color: $bg1;
      border: none;
      height: 45px;
      padding: 5px;
      color: white;
      text-align: center;
      width: 100%;
      border-radius: 10px; } } }

.react-datepicker {
  border: .5px solid $apple;
  border-radius: 10px;
  overflow: hidden; }
.react-datepicker__tab-loop {
  .react-datepicker-popper {
    .react-datepicker__header {
      padding: 0;
      margin-bottom: 6px; }
    .react-datepicker__current-month {
      background-color: $bg1;
      color: white; }
    .react-datepicker__day-names {
      background-color: $apple;
      padding-bottom: 10px;
      color: $bg1; }
    .react-datepicker__month {
      background-color: $bg1;
      margin: 0; }
    .react-datepicker__day {
      background-color: $bg2;
      color: white;
      border-radius: 4px;
      &:hover {
        background-color: $apple;
        color: $bg1; }
      &.react-datepicker__day--disabled {
        pointer-events: none;
        opacity: .4; }
      &.react-datepicker__day--selected {
        background-color: $apple;
        color: $bg1; } } } }

input[type="color"] {
  width: 100%;
  height: 80px;
  border: none; }

.selectDash {
  position: relative;
  border: .5px solid $green-dark;
  select {
    margin: 0; }
  &::after {
    content: '';
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    pointer-events: none;
    background: url('../img/dashboard/arrow_select.svg') center / 20px no-repeat; } }


.bg-b1 {
  background-color: $bg1 !important; }
.bg-b2 {
  background-color: $bg2 !important; }

.btn-save-dash {
  border: 2px solid white;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background: transparent;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    background: url('../img/dashboard/icon_save_dashboard.svg') center / 20px no-repeat;
    margin-right: 4px; }
  &:hover {
    background: $orange;
    color: white;
    border: 2px solid $orange; }
  @media screen and ( max-width: 768px ) {
    height: 55px; } }



.border-botton-greenDark {
  border-bottom: .5px solid $green-dark; }


.actionsListDash {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    border-radius: 6px;
    height: 35px;
    &.newOrder {
      font-size: 13px;
      background-color: $apple;
      color: $bg1;
      padding: 0 8px;
      line-height: 14px;
      &:hover {
        background-color: white; } } }
  @media screen and ( max-width: 768px ) {
    margin-top: 8px;
    button {
      width: 100%; } } }




.btnDots {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background: none;
  margin: 0;
  background: $bg1 url('../img/dashboard/dots_option.svg') center / 20px no-repeat;
  margin-left: 6px;
  display: block;
  &::after {
    display: none; } }

.listDrop {
  background-color: $bg1;
  box-shadow: 0 0 10px rgba(black,.5);
  border: .5px solid $apple;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  button {
    display: block;
    background-color: $bg1;
    border: none;
    color: white;
    border-bottom: .5px solid $w40;
    width: 100%;
    height: 50px;
    border-radius: 0;
    &:nth-last-child(1) {
      border: none; }
    &:hover {
      background-color: $apple !important;
      color: $bg1; }
    @media screen and ( max-width: 768px ) {
      height: 75px; } } }

.orange-label {
  display: inline-flex;
  color: $bg1;
  background: white;
  border-radius: 6px;
  border: .5px solid $guide;
  padding: 5px 20px;
  box-shadow: 0 0 0px $gold;
  &.active {
    animation: blurLink 2.5s linear infinite;
    background: white;
    color: black;
    border: .5px solid $guide; }
  &:hover {
    background-color: black;
    color: white;
    border: .5px solid white; } }


@keyframes blurLink {
  0% {
    box-shadow: 0 0 0px 0px $guide;
    transform: scale(1); }
  50% {
    box-shadow: 0 0 30px 3px $guide;
    transform: scale(1.1); }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0px 0px $guide; } }



.linkApple {
  background-color: $apple;
  color: $bg1;
  text-decoration: underline;
  padding: 3px 10px;
  border-radius: 4px;
  margin: 0 4px;
  &:hover {
    background-color: $gold;
    color: white; } }

.form-check-input {
  &.sm {
    width: 60px !important;
    height: 20px !important; } }


.loadAdmin {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.credits-text {
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 5px;
  border: .5px dotted $apple;
  color: $apple;
  margin: 0 5px;
  height: 28px; }

.dashList {
  list-style-image: url('../img/dashboard/coin.svg');
  padding-left: 10px;
  li {
    margin-bottom: 10px;
    padding-left: 10px; } }



.modal-backdrop {
  z-index: 11; }
.modalDashboard {
  backdrop-filter: blur(2px);
  z-index: 15;
  .modal-content {
    background-color: rgba($bg2,.95);
    backdrop-filter: blur(15px);
    border: .5px solid rgba(white,.2);
    .bg-b1 {
      border: .5px solid rgba($apple,.2); } } }

.coin {
  width: 50px;
  height: 50px; }

.infoMenuH {
  display: flex !important;
  align-items: center;
  padding: 4px 10px;
  font-size: 13px;
  color: white;
  background-color: none;
  border-radius: 4px;
  opacity: .7;
  pointer-events: none;
  &::after {
    content: '';
    display: flex;
    width: 20px;
    min-width: 20px !important;
    height: 20px;
    margin-left: 4px;
    background: url('../img/dashboard/icon_settings.svg') center / contain no-repeat; }
  @media screen and ( max-width: 768px ) {
    width: fit-content !important; } }


.dangerLabel {
  background-color: $orange-dark;
  display: inline-flex;
  padding: 0 10px;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  color:  white;
  font-size: 20px;
  justify-content: center;
  align-items: center; }

.inputAmount {
  border: .5px solid $apple;
  position: relative;
  overflow: hidden;

  input {
    text-align: center;
    height: 60px !important;
    padding: 20px;
    font-size: 25px !important;
    line-height: 0px; } }

.errorDash {
  color: $orange;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 10px;
  &::before {
    content: '!';
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $orange;
    border: .5px solid $orange;
    margin-right: 5px; } }

.btn-apple {
  background-color: $apple;
  width: 100%;
  height: 50px;
  border: .5px solid $apple;
  color: $bg2;
  text-decoration: none;
  &:hover {
    background-color: $green-dark2;
    color: white; } }


.contentNotification {
  position: fixed;
  top: 60px;
  right: 10px;
  background-color: white;
  border: .5px solid $guide;
  color: $bg1;
  width: 400px;
  min-height: 40px;
  padding: 10px 10px 10px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .4s;
  opacity: 0;
  transform: translateX(100%);
  z-index: 100;
  box-shadow: 0 0 20px rgba($guide,.7);
  p {
    margin: 0; }
  button {
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
      content: '';
      transition: all .2s;
      width: 17px;
      height: 2px;
      background-color: $bg1;
      position: absolute; }
    &::before {
      transform: rotate(-45deg); }
    &::after {
      transform: rotate(45deg); }
    &:hover {
      &::before, &::after {
        transform: rotate(0deg); } } }
  &.active {
    transform: translateY(0);
    opacity: 1; }
  &.error {
    background-color: $pink;
    color: $orange-dark;
    border: .5px solid $orange;
    button {
      &::before, &::after {
        background-color: $orange-dark; } } }

  @media screen and ( max-width: 768px ) {
    width: calc( 100vw - 60px );
    padding: 7px 7px 7px 12px;
    top: 57px;
    right: 0px;
    border-radius: 0; } }


.orderList {
  display: flex;
  align-items: center;
  strong {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    background-color: $green;
    margin-right: 15px;
    border-radius: 50%;
    color: $bg1; }
  p {
    color: $orange-light; }
  &.checked {
    opacity: .6;
    pointer-events: none;
    strong {
      background-color: $apple; }
    p {
      text-decoration: line-through; } } }

.labelSettings,.labelTheme, .labelProducts {
  display: inline-flex;
  align-items: center;
  background-color: rgba(black,.1);
  padding: 4px 8px;
  border-radius: 3px;
  margin: 0 5px;
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    margin-right: 5px; } }
.labelSettings {
  &::before {
    background: url('../img/dashboard/icon_settings.svg') center / contain no-repeat; } }
.labelTheme {
  &::before {
    background: url('../img/dashboard/icon_themes.svg') center / contain no-repeat; } }
.labelProducts {
  &::before {
    background: url('../img/dashboard/icon_product.svg') center / contain no-repeat; } }

.btn-line-white {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: .5px solid white;
  background-color: transparent;
  color: white;
  border-radius: 50px;
  &:hover {
    background-color: $purple;
    color: black;
    border: .5px solid $purple; } }


.listDropKitchen {
  border: .5px solid $apple;
  background-color: black;
  .dropdown-item {
    color: white;
    padding: 10px 20px;
    &:hover {
      background: $apple;
      color: black; } } }

.transform-y {
  transform: translateY(-10px); }

.iconInfo {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: none;
  color: black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: 15px;
  margin-bottom: 2px; }
