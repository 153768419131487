.dashpage.corp {
  background: linear-gradient(35deg, $bp2, #404040,$bp2,#404040);
  aside {
    background: linear-gradient(235deg, $bp2, #363535,$bp2); }
  .bg-b1, .bg-b2, .btn-line-apple, .dropAdmin > button, .cardVisitCounter {
    background-color: rgba(#090909, .1) !important;
    backdrop-filter: blur(5px) !important;
    border: .5px solid rgba(white,.2) !important; }
  .btn-line-apple {
    &:hover {
      background-color: $corp !important;
      color: black !important;
      &::before, &::after {
        filter: brightness(0) !important; } } }


  .headerDashboard, .headerMobile {
    background: linear-gradient(65deg, $bp2, #404040,$bp2,#404040);

    .orange-label {
      background: $corp;
      border: none;
      &:hover {
        background-color: white;
        color: $bg2; } }
    .dropAdmin {
      button,a {
        color: white;
        &::after {
          filter: grayscale(1); } } }
    .dropdown-menu {
      border: .5px solid rgba(white, .3);
      button,a {
        background: linear-gradient(65deg, $bp2, #404040,$bp2,#404040);
        &:hover {
          background: $corp !important;
          color: black !important; } } } }
  .tabLateral {
    a,button {
      background-color: transparent;
      border: .5px solid transparent;
      border-right: none;
      &.active, &:hover {
        background-color: rgba(black,.2);
        border: .5px solid rgba(white,.3);
        border-right: none;
        &::before {
          filter: grayscale(1); } } } }
  .headerMobile {
    button {
      background-color: transparent;
      border-radius: 0;
      border-left: .5px solid rgba(white,.3); } }
  .plegableMenu {
    .content {
      background-color: rgba($bp2,.4);
      backdrop-filter: blur(10px);
      border-left: .5px solid $green; } }
  .uploadBtnDash {
    background-color: rgba($bp2,.4); }
  .inputDash, .inputDashDouble {
    background-color: rgba($bp2,.4);
    border: .5px solid rgba(white,.3);
    input {
      background-color: transparent !important;
      border: none !important; }
    select {
      background-color: transparent; } }
  .footerSettingsDash {
    border: none;
    background-color: rgba($bp2,.7);
    backdrop-filter: blur(5px);
    button {
      background-color: $bp2;
      border: .5px solid $green; } }
  .daySchedule, .inputSocial {
    input {
      background-color: rgba($bp2,.7); }
    .link {
      background-color: $bp2;
      border: .5px solid $green; }
    .delete {
      border: .5px solid $corp2;
      background-color: black; } }
  .fieldPayment {
    .inputDash {
      input {
         border: none !important;
         background-color: transparent !important; } } }
  .slideThemeMenu {
    .slick-arrow {
      background-color: black; }
    .slick-dots {
      li {
        button {
          background-color: $corp2; }
        &.slick-active {
          button {
            background-color: $corp; } } } }
    .cardThemeSlide {
      .footerSliderSelect {
        background-color: rgba($bp2,.7);
        border: .5px solid rgba(white,.3);
        .btn-line-apple {
          background-color: $corp2 !important;
          color: black; } } } }
  .wspMenuC, .productSectionD {
    .inputDash {
      padding: 0;
      .bg-b2 {
        border: none !important;
        background-color: transparent  !important; } } }
  .centerSubmit {
    background-color: rgba($bp2,.7);
    border: .5px solid rgba(white,.3);
    button {
      background-color: $corp;
      color: black;
      border: .5px solid $corp;
      &::before {
        filter: brightness(0); } }
    @media screen and ( max-width: 768px ) {
      background-color: black;
      button {
        border-radius: 0; } } }
  .tabsOptionsDash {
    background-color: transparent;
    border: .5px solid rgba(white, .3);
    li {
      .nav-link {
        background-color: transparent;
        &.active {
          background-color: $corp2; } } } }
  .categoryList, .cardViewer, .headPost {
    background-color: rgba($bp2,.7);
    border: .5px solid rgba(white,.3);
    button {
      background-color: rgba($bp2,.7); }
    .h {
      background-color: rgba($bp2,.7);
      color: $corp2; }
    .pointer {
      &.disabled {
        background-color: $bp1; } } }
  .cardListProductsDash {
    background: $bp1;
    backdrop-filter: none !important;
    border: .5px solid rgba(white,.2) !important;
    .dropdown {
      > button,a {
        background-color: transparent; }
      .listDrop {
        button {
          background:  $bp1;
          &:hover {
            background: $corp !important;
            color: black !important; } } } } }
  .accKitchen {
    .accordion-item, .accordion-button, {
      background-color: rgba(#090909, .1);
      border: .5px solid rgba(white,.2);
      backdrop-filter: blur(5px); }
    .accordion-button {
      border: none; } }
  .actionSection {
    background-color: rgba(#090909, .1);
    border: .5px solid rgba(white,.2);
    button,a {
      background-color: rgba(#090909, .1);
      border: .5px solid rgba(white,.2); } }
  .cardOrderResume {
    padding: 10px;
    background-color: rgba(#090909, .1);
    border: .5px solid rgba(white,.2);
    margin-bottom: 10px;
    hr {
      display: none; }
    .btn-delete-dash {
      background-color: rgba(#090909, .1);
      border: .5px solid $corp2; } }
  .btn-link-step {
    background-color: $corp;
    color: black; }
  .btn-download {
    background-color: rgba(black,.4);
    border: .5px solid $green;
    &:hover {
      box-shadow: 0 0 20px rgba(white,.4); } }
  .customerListContent {
    .inputDash {
      padding: 0 !important;
      input {
        border: none !important;
        background-color: transparent !important; } }
    .btnDots {
      background-color: rgba(#090909, .1);
      border: .5px solid rgba(white,.2); }
    .listDrop {
      border: .5px solid rgba(white, .3);
      button,a {
        background: linear-gradient(65deg, $bp2, $bp1,$bp2,$bp1);
        &:hover {
          background: $corp !important;
          color: black !important; } } } }
  .modalDashboard {
    .modal-dialog {
      .modal-content {
        background-color: rgba(#090909, .9);
        backdrop-filter: blur(15px);
        border: .5px solid rgba($corp,.4);
        .modal-body {
          .btnClose {
            background-color: $corp2;
            color: black; }
          .contactMSect {
            background-color: $bp1;
            button, a {
              background-color: rgba(black,.3);
              border: .5px solid $corp;
              color: white; } }
          .btn-line-apple {
            border: .5px solid $corp !important; }
          .btn-orange {
            background-color: $corp2;
            color: black; }
          .radioPaymentSect {
            div {
              background-color: rgba(black,.3);
              border: .5px solid $corp;
              box-shadow: none; } }
          .uploadLogo {
            img {
              filter: grayscale(1); } }
          .footerModalProduct {
            background-color: $bp1;
            border-top: .5px solid $green;
            a,button {
              background-color: $corp; } } } } } } }









.orderInMenu {
  &.corp {
    background-color: rgba(black,.3);
    backdrop-filter: blur(15px);
    .headerOrderInDashboard {
      background: linear-gradient(35deg, $bp2, $bp1,$bp2,$bp1);
      button {
        background-color: $corp2;
        &:before, &::after {
          background-color: black; } } }
    .pAside {
      background-color: rgba(black,.3) !important;
      border-left: .5px solid rgba($green,.6); }
    .productList, .region {
      background-color: rgba(#090909, .3);
      border: .5px solid rgba($corp,.4);
      .groupMenu, .groupMenuCheck {
        background-color: transparent;
        border: .5px solid rgba($corp,.4); } }

    .emptyCarInDash {
      background-color: $corp2;
      color: black; }
    .contentOrderP {
      .counterProduct {
        background-color: rgba(#090909, .3);
        border: .5px solid rgba($corp2,.4); } }
    .text-bg1 {
      textarea {
        background-color: rgba(#090909, .3);
        border: .5px solid rgba($corp,.4); } }
    .addModal {
      background-color: $corp !important;
      border: .5px solid $corp !important;
      color: black; } } }








/* formato premium ---------------------------------------- */





.dashpage.premium {
  background: linear-gradient(35deg, $bp2, $bp1,$bp2,$bp1);
  color: white;
  aside {
    background: linear-gradient(235deg, $bp2, $bp1,$bp2); }
  .bg-b1, .bg-b2, .btn-line-apple, .dropAdmin > button, .cardVisitCounter {
    background-color: rgba(#090909, .1) !important;
    backdrop-filter: blur(5px) !important;
    border: .5px solid rgba(white,.2) !important; }
  .btn-line-apple {
    &:hover {
      background-color: $pre !important;
      color: black !important;
      &::before, &::after {
        filter: brightness(0) !important; } } }

  .headerDashboard, .headerMobile {
    background: linear-gradient(65deg, $bp2, $bp1,$bp2,$bp1);

    .orange-label {
      background: $pre;
      border: none;
      &:hover {
        background-color: white;
        color: $bg2; } }
    .dropAdmin {
      button,a {
        color: white;
        &::after {
          filter: grayscale(1); } } }
    .dropdown-menu {
      border: .5px solid rgba(white, .3);
      button,a {
        background: $bp1;
        &:hover {
          background: $pre !important;
          color: black !important; } } } }
  .tabLateral {
    a,button {
      background-color: transparent;
      border: .5px solid transparent;
      border-right: none;
      &.active, &:hover {
        background-color: rgba(black,.2);
        border: .5px solid rgba(white,.3);
        border-right: none;
        &::before {
          filter: grayscale(1); } } } }
  .headerMobile {
    button {
      background-color: transparent;
      border-radius: 0;
      border-left: .5px solid rgba(white,.3); } }
  .plegableMenu {
    .content {
      background-color: rgba($bp2,.4);
      backdrop-filter: blur(10px);
      border-left: .5px solid white; } }
  .uploadBtnDash {
    background-color: rgba($bp2,.4); }
  .inputDash, .inputDashDouble {
    background-color: rgba($bp2,.4);
    border: .5px solid rgba(white,.3);
    input {
      background-color: transparent !important;
      border: none !important; }
    select {
      background-color: transparent; } }
  .footerSettingsDash {
    border: none;
    background-color: rgba($bp2,.7);
    backdrop-filter: blur(5px);
    button {
      background-color: $bp2;
      border: .5px solid white; } }
  .daySchedule, .inputSocial {
    input {
      background-color: rgba($bp2,.7); }
    .link {
      background-color: $bp2;
      border: .5px solid white; }
    .delete {
      border: .5px solid $pre2;
      background-color: black; } }
  .fieldPayment {
    .inputDash {
      input {
         border: none !important;
         background-color: transparent !important; } } }
  .slideThemeMenu {
    .slick-arrow {
      background-color: black; }
    .slick-dots {
      li {
        button {
          background-color: $pre2; }
        &.slick-active {
          button {
            background-color: $pre; } } } }
    .cardThemeSlide {
      .footerSliderSelect {
        background-color: rgba($bp2,.7);
        border: .5px solid rgba(white,.3);
        .btn-line-apple {
          background-color: $pre2 !important;
          color: black; } } } }
  .wspMenuC, .productSectionD {
    .inputDash {
      padding: 0;
      .bg-b2 {
        border: none !important;
        background-color: transparent  !important; } } }
  .centerSubmit {
    background-color: rgba($bp2,.7);
    border: .5px solid rgba(white,.3);
    button {
      background-color: $pre;
      color: black;
      border: .5px solid $pre;
      &::before {
        filter: brightness(0); } }
    @media screen and ( max-width: 768px ) {
      background-color: black;
      button {
        border-radius: 0; } } }
  .tabsOptionsDash {
    background-color: transparent;
    border: .5px solid rgba(white, .3);
    li {
      .nav-link {
        background-color: transparent;
        &.active {
          background-color: $pre2; } } } }
  .categoryList, .cardViewer, .headPost {
    background-color: rgba($bp2,.7);
    border: .5px solid rgba(white,.3);
    button {
      background-color: rgba($bp2,.7); }
    .h {
      background-color: rgba($bp2,.7);
      color: $pre2; }
    .pointer {
      &.disabled {
        background-color: $bp1; } } }
  .cardListProductsDash {
    background: $bp1;
    backdrop-filter: none !important;
    border: .5px solid rgba(white,.2) !important;
    .dropdown {
      > button,a {
        background-color: transparent; }
      .listDrop {
        button {
          background:  $bp1;
          &:hover {
            background: $pre !important;
            color: black !important; } } } } }
  .accKitchen {
    .accordion-item, .accordion-button, {
      background-color: rgba($bp2, .1);
      border: .5px solid rgba(white,.2);
      backdrop-filter: blur(5px); }
    .accordion-button {
      border: none; } }
  .actionSection {
    background-color: rgba($bp2, .1);
    border: .5px solid rgba(white,.2);
    button,a {
      background-color: rgba($bp2, .1);
      border: .5px solid rgba(white,.2); } }
  .cardOrderResume {
    padding: 10px;
    background-color: rgba($bp2, .1);
    border: .5px solid rgba(white,.2);
    margin-bottom: 10px;
    hr {
      display: none; }
    .btn-delete-dash {
      background-color: rgba($bp2, .1);
      border: .5px solid $pre2; } }
  .btn-link-step {
    background-color: $pre;
    color: black; }
  .btn-download {
    background-color: rgba(black,.4);
    border: .5px solid white;
    &:hover {
      box-shadow: 0 0 20px rgba(white,.4); } }
  .customerListContent {
    .inputDash {
      padding: 0 !important;
      input {
        border: none !important;
        background-color: transparent !important; } }
    .btnDots {
      background-color: rgba($bp2, .1);
      border: .5px solid rgba(white,.2); }
    .listDrop {
      border: .5px solid rgba(white, .3);
      button,a {
        background: linear-gradient(65deg, $bp2, $bp1,$bp2,$bp1);
        &:hover {
          background: $pre !important;
          color: black !important; } } } }
  .modalDashboard {
    .modal-dialog {
      .modal-content {
        background-color: rgba($bp2, .9);
        backdrop-filter: blur(15px);
        border: .5px solid rgba($pre,.4);
        .modal-body {
          .btnClose {
            background-color: $pre2;
            color: black; }
          .contactMSect {
            background-color: $bp1;
            button, a {
              background-color: rgba(black,.3);
              border: .5px solid $pre;
              color: white; } }
          .btn-line-apple {
            border: .5px solid $pre !important; }
          .btn-orange {
            background-color: $pre2;
            color: black; }
          .radioPaymentSect {
            div {
              background-color: rgba(black,.3);
              border: .5px solid $pre;
              box-shadow: none; } }
          .uploadLogo {
            img {
              filter: grayscale(1); } }
          .footerModalProduct {
            background-color: $bp1;
            border-top: .5px solid white;
            a,button {
              background-color: $pre; } } } } } } }









.orderInMenu {
  &.premium {
    background-color: rgba(black,.3);
    backdrop-filter: blur(15px);
    .headerOrderInDashboard {
      background: linear-gradient(35deg, $bp2, $bp1,$bp2,$bp1);
      button {
        background-color: $pre2;
        &:before, &::after {
          background-color: black; } } }
    .pAside {
      background-color: rgba(black,.3) !important;
      border-left: .5px solid rgba(white,.6); }
    .productList, .region {
      background-color: rgba($bp2, .3);
      border: .5px solid rgba($pre,.4);
      .groupMenu, .groupMenuCheck {
        background-color: transparent;
        border: .5px solid rgba($pre,.4); } }

    .emptyCarInDash {
      background-color: $pre2;
      color: black; }
    .contentOrderP {
      .counterProduct {
        background-color: rgba($bp2, .3);
        border: .5px solid rgba($pre2,.4); } }
    .text-bg1 {
      textarea {
        background-color: rgba($bp2, .3);
        border: .5px solid rgba($pre,.4); } }
    .addModal {
      background-color: $pre !important;
      border: .5px solid $pre !important;
      color: black; } } }




.tabsSettings {
  &.corp {
    .nav-tabs {
      border-bottom: .5px solid $corp;
      .nav-item {
        button {
          background-color: rgba(black,.3);
          color: $corp;
          border: .5px solid $corp;
          &.active {
            background-color: $corp;
            color: $bg1; } } } }
    @media screen and ( max-width: 768px ) {
      border-bottom: .5px solid $corp; } }

  &.premium {
    .nav-tabs {
      border-bottom: .5px solid $pre;
      .nav-item {
        button {
          background-color: rgba(black,.3);
          color: $pre;
          border: .5px solid $pre;
          &.active {
            background-color: $pre;
            color: $bg1; } } } }
    @media screen and ( max-width: 768px ) {
      border-bottom: .5px solid $pre; } } }










