.aside {
  position: sticky;
  top: 0;
  padding: 12px;
  height: 100vh;
  margin: 0;
  border-left: .5px solid $c4;
  .content {
    padding: 20px;
    border-radius: 12px;
    background-color: rgba($t3,.2);
    border: .5px solid rgba($t3,.8);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .footerCard, .topCard {
      width: 100%; }

    .footerCard {
      .totalPrice {
        border-top: .5px solid $c4;
        display: flex;
        strong {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          padding: 13px;
          font-size: 12px;
          &:nth-child(1) {
            font-family: $nunito; }
          &.price {
            background-color: rgba($t4,.03);
            font-size: 17px;
            border-radius: 0 0 15px 15px; } } } } }
  @media screen and ( max-width: 768px ) {
    padding: 0;
    border-left: 0;
    .content {
      padding: 0;
      .topCard {
        padding: 15px;
        strong {
          font-size: 20px; }
        .labelP {
          font-size: 14px; } }

      .footerCard {
        position: sticky;
        bottom: 0;
        .totalPrice {
          strong {
            font-size: 15px !important;
            background: transparent !important;
            &.price {
              font-size: 20px !important; } } } } } } }




.btn-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: $c3;
  background-color: $c2;
  border: none;
  font-size: 18px;
  &::before {
    content: '';
    width: calc( 100% - 6px );
    height: calc( 100% - 6px );
    position: absolute;
    top: 3px;
    left: 3px;
    pointer-events: none;
    border: .5px solid rgba($t3,.3);
    border-radius: 8px; }
  &:hover {
    background-color: $c4; }
  &.disabled {
    opacity: .4;
    pointer-events: none; } }

.deleteCart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: rgba($t1,.08);
  color: $c1;
  &:hover {
    color: $c3;
    background-color: $c1; }
  @media screen and ( max-width: 768px ) {
    background-color: rgba($t1,.03);
    border: .5px solid $c1;
    font-size: 16px;
    height: 50px; } }


.labelP {
  background-color: $c2;
  font-size: 11px;
  display: inline-flex;
  padding: 2px 10px;
  margin-left: 10px;
  color: $c3;
  border-radius: 4px; }


.contentProducts {
  max-height: calc( 100vh - 240px );
  overflow-y: auto;
  &.active {
    padding: 20px;
    background-color: rgba($t4,.03);
    border-radius: 8px; }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  &::-webkit-scrollbar-track {
    background-color: $c3;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background-color: $c4;
    border-radius: 10px; }
  @media screen and ( max-width: 768px ) {
    max-height: calc( 100vh - 330px ); } }


.emptyCart {
  width: 100%;
  min-height: calc( 100vh - 240px );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    pointer-events: none;
    border: none;
    box-shadow: none;
    img,svg ,strong {
      opacity: .3; }
    img,svg {
      width: 60px;
      margin-bottom: 10px; } }
  @media screen and ( max-width: 768px ) {
    min-height: 200px; } }


.btnModalConfirm, .btnModalCancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  font-size: 17px;
  strong {
    font-size: 17px; }
  @media screen and ( max-width: 768px ) {
    font-size: 14px;
    strong {
      font-size: 14px; } } }

.btnModalConfirm {
  background-color: $c2;
  color: $c3;
  border: .5px solid $c2; }

.btnModalCancel {
  background-color: rgba($t4,.02);
  color: $c4;
  border: .5px solid rgba($t4,.02); }





.modalSM {
  background-color: rgba($t4,.2);
  backdrop-filter: blur(4px);
  .modal-dialog {
    max-width: 370px;
    .modal-content {
      background-color: $c3;
      color: $c4;
      padding: 50px 30px; }
    @media screen and ( max-width: 768px ) {
      max-width: 100%;
      .modal-content {
        h2 {
          font-size: 35px; }
        p {
          font-size: 17px; } } } } }





.contentFavorite {
  .mainCards {
    .cc {
      img {
        min-width: 100%; } } }
  @media screen and ( max-width: 768px ) {
    .mainCards {
      .cc {
        aspect-ratio: 1/.5;
        max-height: 100px;
        position: relative;
        img {
          min-height: 130px;
          min-width: 100%; } } } } }






.footerAsideMobile {
  justify-content: space-between;
  align-items: center;
  display: none;
  width: 100%;
  button {
    text-transform: uppercase;
    height: 70px;
    width: 100%;
    margin: 4px; }
  @media screen and ( max-width: 768px ) {
    display: flex;
    margin-top: 10px; } }

.z-1 {
  z-index: 1; }


.modalCheckout {
  .modal-dialog {
    min-width: 100vw;
    margin: 0;
    .modal-content {
      border-radius: 0;
      min-height: 100vh;
      overflow-y: auto;
      border: none;
      background-color: $c3;
      color: $c4;
      font-family: $nunito;
      .modal-header {
        border-bottom: .5px solid $c4;
        position: fixed;
        top: 0;
        background-color: $c3;
        z-index: 1;
        width: 100vw; }
      .modal-footer {
        border-top: .5px solid $c4;
        padding: 0;
        position: fixed;
        bottom: 0;
        width: 100vw;
        button {
          width: 100%;
          padding: 20px 0;
          font-size: 17px;
          margin: 0;
          color: $c4;
          background-color: $c3; } }
      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($t4,.03);
        padding: 130px 0;
        p {
          font-size: 16px; }
        .btn-link {
          color: $c4;
          &:hover {
            color: $c2; } }

        .contentCheckout {
          overflow: hidden;
          transform: translateY(-30px); }
        .contentProducts {
          max-height: calc( 100vh - 340px ); } } } }
  @media screen and ( max-width: 768px ) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 0;
          .btn-confirm {
            height: 60px; }
          .contentCheckout {
            transform: translateY(0); }
          .iframeMenu {
            aspect-ratio: 2/1;
            margin-bottom: 30px; } } } } } }



.errorMenu {
  color: $c1;
  display: inline-flex;
  justify-content: center;
  margin: 10px 0;
  &::before {
    content: '!';
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c1;
    border: .5px solid $c1;
    margin-right: 5px; } }

.fe-card {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1.3;
  border-radius: 27px;
  border: 2px solid rgba($t4,.1);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $c3;
  span {
    font-size: 20px;
    color: $c4; }
  svg {
    width: 70px;
    height: 70px;
    fill: $c4;
    margin-bottom: 10px; }
  &:hover {
    border: 2px solid $c2;
    box-shadow: 0 4px $c2; } }


.headCheckout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 50px;
  button {
    position: absolute;
    font-size: 14px;
    left: 0;
    top: 0;
    color: $c4;
    text-decoration: underline; } }




.formMenuCheckout {
  width: 100%;
  .input {
    position: relative;
    width: 100%;
    height: 50px;
    border: .5px solid $c4;
    border-radius: 7px;
    overflow: hidden;
    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      padding: 20px;
      color: $c4;
      font-size: 16px;
      &::placeholder {
        color: rgba($t4,.5);
        font-size: 16px; } } }
  @media screen and ( max-width: 768px ) {
    .input {
      height: 60px; } } }



.selectMenuPayment {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  border-radius: 12px;
  border: 2px solid rgba($t4,.1);
  padding: 15px;
  margin-bottom: 7px;
  position: relative;
  transition: all .2s;
  color: $c4;
  svg {
    width: 50px;
    height: 50px;
    object-fit: contain;
    fill: $c4;
    margin-right: 20px; }
  span {
    font-size: 17px; }
  small {
    position: absolute;
    right: 15px;
    display: inline-flex;
    background: $c2;
    padding: 2px 7px;
    border-radius: 5px;
    color: $c3; }
  input {
    display: none; }
  &.cash {
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border: 2px solid $c4;
      border-radius: 50%;
      position: absolute;
      right: 15px;
      transition: all .3s; }
    &.active {
      border: 2px solid $c4;
      &::after {
        border: 2px solid $c2;
        background-color: $c2; } } }
  &.aspectCheck {
    &::after {
      content: '';
      width: 30px;
      height: 30px;
      border: 2px solid $c4;
      border-radius: 50%;
      position: absolute;
      right: 15px;
      transition: all .3s; } }
  &.aC {
    border: 2px solid $c4; }
  &.disabled {
    opacity: .2;
    pointer-events: none; }
  @media screen and ( max-width: 768px ) {
    svg {
      width: 30px;
      height: 30px; } } }

.sendOrderBtn {
  svg {
    width: 30px;
    height: 30px;
    object-fit: contain;
    fill: $c3;
    margin-right: 10px; }
  &.disabled {
    pointer-events: none;
    opacity: .5; } }

