.add-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: .5px solid $apple;
  color: $apple;
  background-color: $bg2;
  box-shadow: 0 10px 20px 2px rgba($bg1,.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin:  0 auto;
  transition: all .3s;
  &::before, &::after {
    content: '';
    width: 20px;
    height: 1.5px;
    background-color: $apple;
    position: absolute; }
  &::before {
    transform: rotate(90deg); }
  &.active {
    transform: rotate(-45deg); } }

.deletebtn {
  width: 37px;
  height: 37px;
  background: $bg1 url('../img/dashboard/icon_delete.svg') center / 35px no-repeat;
  border-radius: 6px;
  &:active {
    background-color: $apple; } }


.btn-b1, .btn-orange {
  width: 37px;
  height: 37px;
  color: white;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px; }
.btn-b1 {
  background: $bg1; }
.btn-orange {
  background: $orange; }



.cardWaiter {
  background-color: $bg2;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  span {
    display: block;
    padding-left: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    color: white;
    @media screen and ( max-width: 768px ) {
      width: 70px; } } }


