.aboutInfo {
  position: fixed;
  top: 0;
  right: 0;
  width: calc( 100vw - 380px );
  z-index: 100;
  background-color: $c3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  transition: all .2s;
  color: $c4;
  &.active {
    pointer-events: auto;
    opacity: 1; }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .content {
    width: calc( 100vw - 380px );
    height: auto;
    max-height: 100vh;
    padding: 50px 0;
    p {
      font-size: 16px; }
    .container {
      padding-bottom: 30px; } }
  @media screen and ( max-width: 768px ) {
    width: 100vw;
    height: 100vh;
    top: auto;
    bottom: 0;
    .content {
      width: 100vw;
      /* max-height: fit-content */
      p {
        font-size: 16px; }
      .container {
        padding-bottom: 90px; } } } }




.subtitle {
  font-size: 14px;
  text-transform: uppercase;
  color: $c2;
  letter-spacing: 10px;
  @media screen and ( max-width: 768px ) {
    line-height: 23px; } }



.whoIAm {
  margin-right: 50px 0;
  padding: 50px 0; }

.floatFooterMenu {
  z-index: 100;
  background-color: $c3;
  color: $c4;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 90px;
  border-top: .5px solid $c4;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  @media screen and ( max-width: 768px ) {
    display: flex; } }

.btnCloseMenu {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 90px;
  height: 90px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before, &::after {
    content: '';
    width: 30px;
    height: 2px;
    background-color: $c4;
    transition: all .2s;
    position: absolute; }
  &::before {
    transform: rotate(-45deg); }
  &::after {
    transform: rotate(45deg); }
  &:hover {
    &::before, &::after {
      transform: rotate(0deg); } } }


.text-c1 {
  color: $c1; }
.text-c2 {
  color: $c2; }
.text-c3 {
  color: $c3; }
.text-c4 {
  color: $c4; }

.fill-c1 {
  fill: $c1; }
.fill-c2 {
  fill: $c2; }
.fill-c3 {
  fill: $c3; }
.fill-c4 {
  fill: $c4; }

.bg-c1 {
  background: $c1; }
.bg-c2 {
  background: $c2; }
.bg-c3 {
  background: $c3; }
.bg-c4 {
  background: $c4; }


.iframeMenu {
  width: 100%;
  height: auto;
  aspect-ratio: 1/.5;
  @media screen and ( max-width: 768px ) {
    aspect-ratio: 1/1; } }


.tableMenu {
  margin-top: 30px;
  width: 100%;
  thead {
    border-top: .5px solid $c4;
    border-bottom: .5px solid $c4;
    th {
      padding: 20px 0;
      text-align: center; } }
  tbody {
    border-bottom: .5px solid $c4;
    tr {
      background-color: rgba($t4,.02);
      text-align: center;
      transition: all .2s;
      &:nth-child(2n+1) {
        background-color: transparent; }
      td {
        padding: 15px; }
      &:hover {
        background-color: $c4;
        color: $c3; } } } }

.faqsMenu {
  padding: 70px 0;
  a {
    text-decoration: underline;
    color: $c1; }
  p {
    strong {
      font-size: 17px; } } }

.cardOrder {
  border-bottom: .5px dashed rgba($t4,.15);
  padding: 9px 0;
  margin: 5px 0;
  overflow: hidden;
  p {
    font-size: 16px;
    line-height: 17px; }
  small {
    font-size: 13px;
    line-height: 16px;
    opacity: .6; }

  .counterProduct {
    width: 100%;
    border: none;
    button {
      width: 30px;
      height: 30px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      &:hover {
        background-color: transparent; } }

    span {
      font-size: 15px; } }
  @media screen and ( max-width: 768px ) {
    strong {
      font-size: 18px !important; } } }

.contentTycMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($t4,.4);
  backdrop-filter: blur(10px);
  z-index: 10000;
  transition: all .2s;
  pointer-events: none;
  opacity: 0;
  .contentTyc {
    border-top: .5px solid $c4;
    transition-delay: .2s;
    transition: all .2s;
    background-color: $c3;
    color: $c4;
    padding: 40px 40px 0 40px;
    border-radius: 10px;
    width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    transform: translateY(-100px);
    opacity: 0;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .footerM {
      position: sticky;
      bottom: 0;
      background-color: $c3;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      margin-top: 40px;
      button {
        width: 240px; } } }
  &.active {
    opacity: 1;
    pointer-events: auto;
    .contentTyc {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and ( max-width: 768px ) {
    padding: 10px;
    .contentTyc {
      width: 100%;
      padding: 20px;
      padding-bottom: 0;
      height: auto;
      .footerM {
        padding: 10px 0;
        width: 100%;
        button {
          width: 100%;
          height: 65px; } } } } }



.mapPrev {
  text-align: center;
  background-color: rgba($t4,.03);
  color: rgba($t4,.6);
  width: 100%;
  height: auto;
  aspect-ratio: 2/.6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }



