.tabsOptionsDash {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bg2;
  padding: 8px;
  border-radius: 40px;
  border: none;
  box-shadow: 0 0 10px rgba(black,.2);
  li.nav-item {
    width: calc( 100% / 3 );
    display: block;
    &.disabled {
      opacity: .4;
      pointer-events: none; }
    button.nav-link {
      width: 100%;
      height: 50px;
      border-radius: 30px;
      border: none;
      background-color: $bg2;
      color: $w40;
      &:hover {
        color: white; }
      &.active {
        background-color: $gold;
        color: $bg1; } } }
  @media screen and ( max-width: 768px ) {
    li.nav-item {
      button.nav-link {
        padding: 4px;
        font-size: 14px; } } } }




.categoryList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bg1;
  padding: 4px;
  border-radius: 6px;
  margin-bottom: 10px;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    width: 100%;
    p {}
    span {} }
  .options {
    min-width: fit-content;
    margin-left: 10px;
    display: flex;
    align-items: center; } }


.btn-delete-dash, .btn-edit-dash, .btn-move-dash {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 6px;
  &.disabled {
    opacity: .5;
    pointer-events: none; } }

.btn-delete-dash {
  background: $bg2 url('../img/dashboard/icon_delete.svg') center / 40px no-repeat; }
.btn-edit-dash {
  background: $bg2 url('../img/dashboard/icon_edit_dash.svg') center / 25px no-repeat; }
.btn-move-dash {
  background: $bg2 url('../img/dashboard/icon_move.svg') center / 25px no-repeat; }


// create product //-------------------


.cardViewer {
  padding: 0px 15px 15px 15px;
  border-radius: 15px;
  border: .5px solid $w40;
  background-color: $bg1;
  display: flex;
  flex-direction: column;
  align-items: center;
  span.h {
    display: inline-flex;
    margin: 0 auto;
    background-color: $bg2;
    color: $w40;
    padding: 2px 15px;
    border-radius: 0 0 10px 10px; }
  .contentCardProduct {
    background-color: #e7e7f2;
    border-radius: 15px;
    padding: 13px;
    margin-top: 20px;
    color: $bg1;
    width: 100%;

    .label {
      position: absolute;
      width: fit-content;
      left: 0;
      bottom: 0;
      background-color: $gold-dark;
      box-shadow: 0 0 10px rgba(black,.1);
      color: white;
      font-size: 13px;
      border-radius: 0 20px 0 0;
      padding: 3px 10px; }
    .img {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;

      button, span {
        display: block;
        position: absolute;
        pointer-events: none;
        margin: 5px; }
      button {
        width: 30px;
        height: 30px;
        top: 0;
        right: 0;
        background: url('../img/dashboard/favoriteDash.svg') center / 33px no-repeat; }
      span {
        width: 35px;
        height: 35px;
        bottom: 0;
        right: 0;
        border-radius: 30px 5px 10px 5px;
        background: $orange-dark url('../img/dashboard/chillyDash.svg') center / 40px no-repeat; } }
    .content {
      strong.titleP {
        display: block;
        font-weight: bold;
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden; }
      p { // description
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 10px;
        font-size: 14px; }
      .before {
        font-size: 18px;
        color: $orange;
        font-size: 14px;
        .price {
          text-decoration: line-through;
          display: inline-flex;
          margin-left: 5px; } }
      .after {
        font-size: 20px;
        font-weight: bold; } }
    @media screen and ( max-width: 768px ) {
      .label {
        font-size: 16px; }
      .img {
        span {
          width: 50px;
          height: 50px;
          background-size: 50px; } }
      .content {
        strong.titleP {
          font-size: 18px;
          padding: 0; }
        p {
          font-size: 16px; }
        .after {
          font-size: 30px; } } } } }





.productPhotoDash {
  width: 100%;
  height: auto;
  aspect-ratio: 4/2.665;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 4/2.665;
    object-fit: cover; }
  input {
    opacity: 1 !important; } }

.label-apple, .label-orange {
  font-size: 14px;
  display: inline-flex;
  padding: 2px 10px;
  border-radius: 7px; }
.label-apple {
  background-color: $apple;
  color: $bg1; }
.label-orange {
  background-color: $orange;
  color: white; }


.groupOneOptionLabel {
  border: .5px solid $bg1;
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  width: 100%;
  position: relative;
  span {
    opacity: .7;
    font-size: 14px;
    display: block;
    width: 60%;
    line-height: 15px; }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    strong {
      font-family: arial;
      display: block;
      padding-right: 5px;
      font-size: 14px; }
    input {
      width: 25px;
      height: 25px;
      filter: hue-rotate(209deg); } }
  &.disabled {
    pointer-events: none;
    opacity: .5; }
  @media screen and ( max-width: 768px ) {
    span {
      width: 50%; } } }

.deletedOption {
  position: absolute;
  right: -30px;
  top: 8px;
  width: 30px;
  height: 35px;
  border: none;
  background-color: $orange;
  color: white;
  border-radius: 0 5px 5px 0;
  &:hover {
    background-color: $orange-dark; } }

textarea {
  resize: none; }

.destacadoDash {
  color: $apple;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  &::before {
    content: '⭐';
    width: 25px;
    height: 25px;
    margin-right: 5px;
    filter: brightness(10);
    text-shadow: 0 0 3px $apple; } }


.alertFields {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: .5px solid $orange-dark;
  background-color: $orange;
  color: white;
  margin-top: 20px;
  text-align: center; }


.fCreateProduct {
  @media screen and ( max-width: 768px ) {
    background-color: transparent;
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc( (100vw - 60px)/2 );
    padding: 5px;
    z-index: 2; } }

.btnView {
  display: none;
  height: 85px;
  z-index: 2;
  position: fixed;
  padding: 5px;
  width: calc( (100vw - 60px)/2 );
  right: calc( (100vw - 60px)/2 );

  @media screen and ( max-width: 768px ) {
    display: flex;
    bottom: 0;
    padding: 0;
    height: 70px;
    button {
      width: 100%;
      justify-content: center;
      align-items: center;
      background: $green url('../img/dashboard/view_product.svg') center / 40px no-repeat;
      &:hover {
        background: $apple url('../img/dashboard/view_product.svg') center / 40px no-repeat; } } } }

.btn-back {
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: underline;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background: url('../img/dashboard/arrow_select.svg') center / 20px no-repeat;
    transform: rotate(90deg); } }

#modalViewProduct {
  .modal-dialog {
    .modal-content {
      border-radius: 12px;
      .modal-body {
        padding: 0 !important;
        .groupOneOptionLabel {
          @media screen and ( max-width: 768px ) {
            height: 65px; } } } } } }


.footerModalProduct {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: .5px solid white;
  background-color: $bg1;
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  button {
    background-color: $apple;
    width: 250px;
    height: 60px;
    font-size: 17px;
    border-radius: 12px; } }

.border-dots {
  border-top: .5px dotted $apple;
  box-shadow: 0 10px 30px  black;
  opacity: .3; }

.cardListProductsDash {
  .btnDots {
    border-radius: 7px;
    margin: 0;
    background-position-x: right; }
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/1;
    object-fit: cover; }
  &.featured {
    background: linear-gradient(124deg, rgba(164,142,21,1) 0%, rgba(233,208,129,1) 34%, rgba(174,152,37,1) 60%, rgba(226,202,118,1) 80%, rgba(164,142,21,1) 100%) !important;
    box-shadow: 0 5px 10px rgba(black,.4);
    color: $bg1;
    .text-apple {
      color: $bg1; }
    .col-6 {
      border-top: .5px dotted rgba(black,.3); }
    .dropdown {
      .btnDots {
        background: transparent url('../img/dashboard/dots_option_active.svg') center / 20px no-repeat; } }
    .listDrop {
      background: linear-gradient(124deg, rgba(164,142,21,1) 0%, rgba(233,208,129,1) 34%, rgba(174,152,37,1) 60%, rgba(226,202,118,1) 80%, rgba(164,142,21,1) 100%) !important;
      box-shadow: 0 0 10px rgba(black,.5);
      border: .5px solid $gold;
      padding: 0;
      border-radius: 10px;
      overflow: hidden;
      button {
        display: block;
        background-color: $bg1;
        border: none;
        color: rgba(white,.9);
        border-bottom: .5px solid $gold;
        width: 100%;
        height: 50px;
        border-radius: 0;
        &:nth-last-child(1) {
          border: none; }
        &:hover {
          background-color: transparent;
          color: $bg1; }
        @media screen and ( max-width: 768px ) {
          height: 75px; } } } } }


.catalogSubmit {
  width: 200px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  @media screen and ( max-width: 768px ) {
    width: calc( 100vw - 60px );
    padding: 5px;
    button {
      width: 100%;
      height: 80px; } } }

