.cardThemeSlide {
  padding: 30px;
  text-align: center;
  border-radius: 12px;
  h6 {
    color: $apple; }
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/1.4;
    object-fit: contain;
    margin: 10px 0; }
  .footerSliderSelect {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    background-color: $bg1;
    a,.btn-line-apple {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 !important;
      cursor: pointer; }
    a {
      color: $apple;
      text-decoration: underline; } }
  input {
    display: none; }
  &.selected {
    background: rgba($green-dark2,.04);
    border: .5px solid rgba($green,.4);
    box-shadow: 0 0 10px rgba($green,0);
    animation: blurSelect 2s ease-in-out infinite;
    .btn-line-apple {
      background-color: $green-dark2;
      color: white;
      opacity: 1;
      border: none; } }
  @media screen and ( max-width: 768px ) {
    padding: 0px;
    &.selected {
      border: none;
      animation: none; } } }


@keyframes blurSelect {
  0% {
    box-shadow: 0 0 10px rgba($green,0); }
  50% {
    box-shadow: 0 0 10px rgba($green,.2); }
  100% {
    box-shadow: 0 0 10px rgba($green,0); } }





.btn-tiktok {
  width: 100%;
  height: 50px;
  border: .5px solid $guide;
  color: white;
  border-radius: 6px;
  background-color: black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    background: url('../img/vector/tiktok.svg') center / contain no-repeat;
    margin-right: 10px; }
  &:hover {
    background-color: $guide; } }


.btn-line-apple {
  width: 100%;
  height: 50px;
  border: .5px solid $apple;
  color: white;
  border-radius: 6px;
  background-color: $bg1;
  &.addClient {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: '';
      width: 35px;
      height: 35px;
      background:  url('../img/dashboard/add_user_icon.svg') center / 32px no-repeat;
      margin-right: 10px; }
    &:hover {
      background-color: $green-dark2;
      color: white;
      border: .5px solid $green-dark2; } }
  &:hover {
    background-color: $apple;
    color: $bg1; }
  &.excel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: '';
      width: 35px;
      height: 35px;
      background:  url('../img/dashboard/excel.svg') center / 32px no-repeat;
      margin-right: 10px; }
    &:hover {
      background-color: $green-dark2;
      color: white;
      border: .5px solid $green-dark2; } }
  &.disabled {
    opacity: .3;
    pointer-events: none; }
  @media screen and ( max-width: 768px ) {
    height: 55px; } }
.btn-orange {
  width: 100%;
  height: 50px;
  border: .5px solid $orange;
  color: white;
  border-radius: 6px;
  background-color: $orange;
  &:hover {
    background-color: $orange;
    color: white; }
  @media screen and ( max-width: 768px ) {
    height: 55px; } }


.textareaDash {
  border: .5px solid rgba($w40,.2);
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  background: transparent;
  resize: none;
  color: white;
  &.text-orange {
    color: $orange !important; }
  &::placeholder {
    color: rgba($w40,.4);
    font-size: 14px; } }

.textEditorDash {
  .ql-toolbar {
    background-color: $orange-light;
    button, select, .ql-header {
      transition: none;
      &:hover, &:active {
        filter: hue-rotate(208deg); } } } }


.sectionThemeDash {
  position: relative; }

.btnSave {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 70px;
  border-radius: 6px;
  border: .5px solid $w40;
  color: white;
  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background: transparent url('../img/dashboard/icon_save_dashboard.svg') center / 30px no-repeat;
    margin-right: 10px; } }

.footerSaveAll {
  display: flex;
  justify-content: center;
  border: .5px solid;
  width: 250px;

  button {}

  &.disabled {
    pointer-events: none;
    button {
      border: none; } }
  @media screen and ( max-width: 768px ) {

    text-align: center;
    padding: 7px 50px;
    background-color: $bg2;
    button {
      width: 100%;
      height: 75px; } } }


.mapouter,.gmap_canvas,.gmap_iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.5;
  @media screen and ( max-width: 768px ) {
    aspect-ratio: 1/1.2; } }

.mapouter {
  border-radius: 15px;
  overflow: hidden; }


.lockedMenu {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $orange-light;
  .content {
    width: 460px;
    box-shadow: 0 0 20px rgba(black, .04);
    padding: 40px;
    border-radius: 30px;
    position: relative;
    &::before {
      content: '';
      width: calc( 100% - 20px );
      height: calc( 100% - 20px );
      border: 3px dotted rgba($green,.3);
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 25px;
      pointer-events: none; }
    .fooder {
      width: 140px;
      margin-bottom: 12px; }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      .locked {
        width: 25px;
        height: 25px;
        object-fit: contain;
        margin-right: 15px; }
      strong {} }
    .btnGreen {
      &:hover {
        color: white; } } }
  @media screen and ( max-width: 768px ) {
    padding: 30px;
    align-items: flex-start;
    .content {
      width: 100%; } } }

.slideThemeMenu {
  .slick-arrow {
    border: .5px solid $apple;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 1;
    z-index: 1;
    &::before {
      content: ''; }
    &.slick-prev {
      left: 40px;
      background: $bg1 url('../img/dashboard/arrow_prev.svg') center / 26px no-repeat; }
    &.slick-next {
      right: 40px;
      background: $bg1 url('../img/dashboard/arrow_next.svg') center / 26px no-repeat; } } }


.centerSubmit {
  width: 220px;
  position: fixed;
  bottom: 9px;
  right: calc(50% - ((220px + 210px)/2));
  display: flex;
  justify-content: space-between;
  background: $bg1;
  padding: 10px;
  border: .5px solid rgba(white,.2);
  border-radius: 10px;
  button {
    height: 70px; }
  &.disabled {
    button {
      opacity: .4;
      border: none; } }
  @media screen and ( max-width: 768px ) {
    width: calc( 100% - 60px );
    right: 0;
    bottom: 0;
    border-radius: 0;
    padding: 0;
    border: none;
    border-top: .5px solid $green;
    button {
      width: 100%;
      border: none; }
    &.saveRight {
      width: calc( (100vw - 60px)/2 );
      right: 0;
      border: none;
      button {
        width: 100%;
        border-radius: 0;
        &::before {
          margin-left: -40px; } } } } }


.groupRadio {
  margin-top: 15px;
  label {
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 15px;
    cursor: pointer;
    input {
      display: none;
      &:checked ~ div {
        border: .5px solid $green;
        box-shadow: 3px 3px 0px 0 $green;
        background-color: black;
        &::before {
          background-color: $green;
          border: 2px solid $green; }
        h6 {
          color: white; } } }

    div {
      background-color: $bg2;
      border-radius: 12px;
      padding: 12px;
      width: 100%;
      height: 100%;
      padding-left: 50px;
      position: relative;
      display: flex;
      align-items: center;
      border: .5px solid rgba(white,.7);
      box-shadow: 3px 3px 0px 0 rgba(white,.7);
      &::before {
        content: '';
        width: 25px;
        height: 25px;
        border: 2px solid rgba(white,.7);
        border-radius: 50%;
        position: absolute;
        left: 14px;
        transition: all .2s; }
      h6 {
        margin: 0;
        color: rgba(white,.7); } } } }
