.printArea {
  background: #efede3;
  width: 8cm;
  min-width: 8cm;
  padding: 35px;
  color: black;
  border-radius: 6px;
  .logoOrder {
    filter: grayscale(1) brightness(0);
    width: 180px;
    margin-bottom: 10px; }
  strong, p {
    font-size: 11px; }
  p {
    margin: 0;
    line-height: 13px; }
  .qrCode {
    padding: 0;
    border-radius: 0; }
  hr {
    border-top: 2px dashed black;
    opacity: 1;
    margin: 9px 0; }
  table {
    width: 100%;
    font-size: 11px;
    tr {
      td {
        padding: 6px 0;
        vertical-align: top;
        &:nth-child(1) {
          text-align: center;
          width: 25px; }
        &:nth-child(2) {
          padding-left: 16px; }
        &:nth-child(3) {
          text-align: end; } } } } }




.text-legal {
  display: block;
  font-size: 9px;
  line-height: 10px; }

.checkboxStyle {
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 9px;
  input {
    display: none;
    &:checked + span::before {
      background: $apple url('../img/dashboard/checkbox.svg') center / 15px no-repeat; } }
  span {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background: transparent url('../img/dashboard/checkbox.svg') center / 0px no-repeat;
      border: .5px solid $apple;
      margin-right: 10px;
      border-radius: 5px;
      transition: all .2s; } }
  &.disabled {
    pointer-events: none;
    border: .5px dotted $apple;
    border-radius: 6px;
    padding: 10px;
    background-color: $bg1;
    span {
      opacity: .5; } } }

.checkboxGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputDash {
    max-width: 100px;
    height: 40px;
    input {
      height: 100%; } } }

