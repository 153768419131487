$lg : #d6d6c2;
$ad : #000000;
$purple2: #6721b7;
$aqua2: #71f9cb;

.pa {
  position: absolute;
  z-index: 1; }

.landingPage {
  background: rgba(gray,.1);
  background-attachment: fixed;
  color: black;
  .errorDash {
    transform: translateY(-7px);
    color: $orange-dark;
    &::before {
      border: .5px solid $orange-dark;
      color: white;
      background-color: $orange-dark; } }
  img {
    pointer-events: none; }
  position: relative;
  h1 {
    span {
      display: inline-flex;
      background-color: $aqua2;
      color: black;
      padding: 5px 10px; } }
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 0;
    max-height: 1000px;
    position: relative;
    overflow: visible !important;
    .l1 {
      left: 50px;
      top: 50px;
      width: 70px; }
    .l2 {
      width: 120px;
      right: 0;
      top: 20px; }
    .l3 {
      width: 200px;
      left: 0;
      bottom: -110px; }
    .l4 {
      width: 150px;
      right: 40px;
      bottom: -70px; } }
  .l5 {
    width: 120px;
    left: -70px;
    bottom: 0; }
  @media screen and ( max-width: 768px ) {
    h1 {
      font-size: 25px; }
    .hero {
      padding: 40px 0;
      .l1, .l2 {
        display: none; }
      .l3 {
        width: 140px;
        bottom: -180px; }
      .l4 {
        width: 95px;
        bottom: -80px;
        right: 10px; } } } }





.landingHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  backdrop-filter: blur(6px);
  padding: 20px;
  box-shadow: 0 0 20px rgba(black,.1);
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      display: flex;
      align-items: center;
      a {
        display: inline-flex;
        img {
          width: 110px; } }
      span {
        font-size: 13px;
        display: inline-flex;
        padding-left: 20px;
        margin-left: 20px;
        border-left: .5px solid black;
        @media screen and ( max-width: 768px ) {
          display: none; } } }

    nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .login {
        border: .5px solid black;
        border-radius: 40px;
        padding: 8px 12px 8px 20px;
        display: inline-flex;
        align-items: center;
        color: black;
        &::after {
          content: '';
          width: 25px;
          height: 25px;
          margin-left: 5px;
          background: url('../img/vector/login_landing.svg') center / contain no-repeat; }
        &:hover {
          background-color: white; } }
      @media screen and ( max-width: 768px ) {
        .login {
          background-color: white;
          font-size: 17px; } }


      .burgerMenu {
        width: 40px;
        height: 40px;
        border: none;
        margin-left: 20px;
        background: transparent url('../img/vector/burger_landing.svg') center / contain no-repeat; } } } }

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 30px 0 15px 0;
  button {
    width: 220px; }
  @media screen and ( max-width: 768px ) {
    flex-direction: column-reverse;
    button {
      width: 100%; } } }


.btnLine {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 17px;
  height: 55px;
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  &:hover {
    background-color: black !important;
    color: white !important; } }

.btnRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  font-size: 17px;
  height: 55px;
  color: white;
  border: 2px solid $ad;
  background-color: $ad;
  border-radius: 8px;
  &::after {
    content: '';
    width: 50px;
    height: 17px;
    margin-left: 5px;
    background: url('../img/vector/arrow_right_landing.svg') center / contain no-repeat; } }

.bg-light2 {
  background-color: #f2f2f2; }

.ladingBenefits {
  background-color: white;
  min-height: 100vh;
  padding: 60px 0;
  color: $ad;
  position: relative;
  overflow-x: visible;
  .l6 {
    width: 150px;
    top: -70px;
    left: 0; }
  .l7 {
    right: 0;
    top: -70px;
    width: 200px; }
  .l8 {
    bottom: -60px;
    left: 0;
    width: 80px; }
  .l9 {
    right: 0;
    bottom: 15%;
    width: 180px; }
  @media screen and ( max-width: 768px ) {
    padding: 20px 0;
    min-height: auto;
    .l6, .l7, .l8, .l9 {
      display: none; } } }



.cardBenefits {
  background: white;
  border: .5px solid $ad;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 0;
  color: $ad;
  min-height: 100px;
  img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    fill: $ad; }
  p {
    color: $ad;
    width: 100%; } }

.bg-tl {
  padding: 50px;
  @media screen and ( max-width: 768px ) {
    padding: 0; } }


.slideComments {
  pointer-events: none; }


.cardComments {
  background: white;
  padding: 25px;
  color: black;
  margin: 5px;
  img {
    width: 100px;
    margin-bottom: 20px; }
  @media screen and ( max-width: 768px ) {
    h5 {
      font-size: 17px; }
    p {
      font-size: 13px; }
    strong {
      font-size: 14px; } } }



.landingFooter {
  background-color: #e4e4e4;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
  button, a {
    width: 210px; }
  .btnLine {
    background-color: white; }
  .l11 {
    width: 150px;
    bottom: 90px;
    left: 50px; }
  .l12 {
    width: 180px;
    bottom: 0;
    right: 50px; }
  @media screen and ( max-width: 768px ) {
    padding: 90px 0 140px 0;
    flex-direction: column-reverse;
    button,a {
      width: 250px;
      &.btnLine {
        margin-top: 25px; } }
    .l11 {
      bottom: auto;
      top: -15px;
      left: auto;
      width: 110px;
      right: calc( 50vw - 45px ); }
    .l12 {
      right: calc( 50vw - 90px ); } } }

.textLink {
  color: black;
  text-decoration: underline;
  letter-spacing: 1px; }

.closeBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before, &::after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: $lg;
    position: absolute;
    transition: all .3s;
    border-radius: 3px; }
  &::before {
    transform: rotate(-45deg); }
  &::after {
    transform: rotate(45deg); }
  &:hover {
    &::before, &::after {
      transform: rotate(0deg); } } }


.modalLogin {
  .modal-dialog {
    max-width: 480px;
    .modal-content {
      background: white;
      border-radius: 30px;
      border: .5px solid rgba(white,.3);
      .modal-header {
        padding: 20px 0;
        border: none;
        .short {
          width: 50px;
          height: 50px;
          object-fit: contain;
          background-color: black;
          padding: 10px;
          border-radius: 50%;
          margin-right: 20px; } }
      .modal-body {
        padding: 0;
        .leaf {
          left: -20px;
          width: 50px;
          top: 10px;
          transform: rotate(80deg); } } } } }

.inputLanding, .inputPass {
  display: block;
  width: 100%;
  height: 55px;
  border: .5px solid black;
  color: black;
  border-radius: 10px;
  background-color: transparent;
  padding: 0 15px;
  margin-bottom: 15px; }

.inputPass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  input {
    background-color: transparent;
    height: 55px;
    width: 100%;
    border: none;
    padding-left: 20px;
    color: black; }
  button {
    width: 40px;
    height: 40px;
    border: none;
    background: url('../img/vector/visible_pass.svg') center / 30px no-repeat;
    transform: translateY(-3px);
    &.hide {
      transform: translateY(-1px);
      background: url('../img/vector/hide_pass.svg') center / 30px no-repeat; } } }

.errorAuth {
  display: block;
  padding: 15px;
  border-radius: 10px;
  background-color: $orange;
  color: white;
  font-size: 14px;
  margin-bottom: 20px;
  button {
    font-family: $titleDash;
    color: white;
    text-decoration: underline; } }




.selectStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  border: .5px solid black;
  color: black;
  border-radius: 10px;
  background-color: transparent;
  margin-bottom: 15px;
  position: relative;
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    margin-right: 10px;
    background: url('../img/vector/arrow_down_black.svg') center / 20px no-repeat;
    pointer-events: none; }
  select {
    cursor: pointer;
    padding: 0 15px;
    background-color: transparent;
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    color: black;
    -webkit-appearance: none; } }


.loadLanding {
  position: fixed;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  transition: opacity .3s;
  opacity: 0;
  pointer-events: none;
  div {
    width: 70px;
    height: 70px;
    border: 7px solid rgba(white,.1);
    border-left: 5px solid $aqua2;
    animation: none;
    border-radius: 50%; }
  &.active {
    opacity: 1;
    pointer-events: auto;
    div {
      animation: loading .8s linear infinite; } } }

@keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.btnPurple {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 50px;
  border: .5px solid $aqua2;
  background-color: $aqua2;
  color: $ad;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba($aqua2,.5);
  &:hover {
    background-color: $ad;
    color: $aqua2;
    box-shadow: 0 0 10px rgba($aqua2,.5); }
  &.disabled {
    opacity: .5;
    pointer-events: none;
    box-shadow: none; } }


.demos {
  position: fixed;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: all .3s;
  pointer-events: none;
  opacity: 0;
  transform: scale(.5);
  overflow: hidden;
  &.active {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1); }
  > div {
    max-height: 100vh; }
  @media screen and ( max-width: 768px ) {
    background: linear-gradient(90deg, #e1e9ff, #ffedf0);
    > div {
      overflow: hidden; } } }





.slideDemo {
  .slick-arrow {
    width: 90px;
    height: 90px;
    border: .5px solid black;
    border-radius: 50%;
    &::before {
      content: ''; }
    &.slick-prev {
      background: url('../img/dashboard/arrow_prev_black.svg') center / 20px no-repeat;
      left: -100px; }
    &.slick-next {
      background: url('../img/dashboard/arrow_next_black.svg') center / 20px no-repeat;
      right: -100px; } }
  @media screen and ( max-width: 768px ) {
    .slick-arrow {
      display: none !important; } } }


.scrollTYC {
  max-height: calc( 100vh - 200px );
  overflow-y: auto;
  padding: 10px 0;
  @media screen and ( max-width: 768px ) {
    max-height: auto;
    overflow-y: none; } }

