@font-face {
  font-family: 'amatic';
  src: url('./fonts/AmaticSC-Bold.ttf') format("truetype"); }
@font-face {
  font-family: 'nunito-black';
  src: url('./fonts/Nunito-Black.ttf') format("truetype"); }
@font-face {
  font-family: 'nunitoregular';
  src: url('./fonts/Nunito-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'nunitolight';
  src: url('./fonts/Nunito-Light.ttf') format("truetype"); }
@font-face {
  font-family: 'silom';
  src: url('./fonts/Silom.ttf') format("truetype"); }
@font-face {
  font-family: 'rye';
  src: url('./fonts/Rye-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'ubuntuBold';
  src: url('./fonts/Ubuntu-Medium.ttf') format("truetype"); }
@font-face {
  font-family: 'kalam';
  src: url('./fonts/Kalam-Bold.ttf') format("truetype"); }
@font-face {
  font-family: 'baskerville';
  src: url('./fonts/LibreBaskerville-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'dyna';
  src: url('./fonts/DynaPuff-VariableFont_wdth,wght.ttf') format("truetype"); }
@font-face {
  font-family: 'lora';
  src: url('./fonts/Lora-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'neucha';
  src: url('./fonts/Neucha-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'parisine';
  src: url('./fonts/Parisienne-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'dmserif';
  src: url('./fonts/DMSerifDisplay-Regular.ttf') format("truetype"); }
@font-face {
  font-family: 'rancho';
  src: url('./fonts/Rancho-Regular.ttf') format("truetype"); }


$amatic: 'amatic';
$nunitoblack: 'nunito-black';
$nunitoregular: 'nunitoregular';
$nunitolight: 'nunitolight';
$silom: 'silom';
$rye: 'rye';
$ubuntuBold: 'ubuntuBold';
$kalam: 'kalam';
$baskerville: 'baskerville';
$dyna: 'dyna';
$lora: 'lora';
$neucha: 'neucha';
$parisine: 'parisine';
$dmserif: 'dmserif';
$rancho: 'rancho';

.f-1 {
  font-family: $amatic; }
.f-2 {
  font-family: $nunitoblack; }
.f-3 {
  font-family: $nunitoregular; }
.f-4 {
  font-family: $nunitolight; }
.f-5 {
  font-family: $silom; }
.f-6 {
  font-family: $rye; }
.f-7 {
  font-family: $ubuntuBold; }
.f-8 {
  font-family: $kalam; }
.f-9 {
  font-family: $baskerville; }
.f-10 {
  font-family: $dyna; }
.f-11 {
  font-family: $lora; }
.f-12 {
  font-family: $neucha; }
.f-13 {
  font-family: $parisine; }
.f-14 {
  font-family: $dmserif; }
.f-15 {
  font-family: $rancho; }


.contentPost {
  height: calc( 100vh -  50px);
  overflow-y: auto;
  @media screen and ( max-width: 768px ) {
    overflow-y: none;
    .uploadBtnDash {
      font-size: 14px;
      &::after {
        width: 17px;
        height: 17px; } } } }



.headPost {
  background-color: $bg1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  div {
    z-index: 1;
    display: flex;
    .pointer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      border: .5px solid $apple;
      border-radius: 50%;
      background-color: $apple;
      margin-left: 13px;
      strong, span, small {
        display: block;
        text-align: center;
        line-height: 16px;
        color: $bg1; }
      small {
        font-size: 10px; }
      strong {
        font-size: 20px; }
      &.disabled {
        background-color: $bg2;
        border: .5px dashed rgba(white,.3);
        small, strong {
          color: rgba(white,.3); } } } }
  @media screen and ( max-width: 768px ) {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    h5 {
      font-size: 17px;
      padding: 15px 0 !important; }
    div {
      display: flex;
      justify-content: center;
      .pointer {
        margin: 0 5px; } } } }


.contentDesign {
  height: calc( 100vh - (80px + 60px + 80px) );
  @media screen and ( max-width: 768px ) {
    height: fit-content;
    margin-bottom: 100px; } }




.poster {
  width: 1000px;
  height: 1000px;
  overflow: hidden;
  background: $green-dark2 url('../img/dashboard/texture_post.svg') center / 620px repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 15.7%; }
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }
  .svg {
    width: 100%;
    height: 100%;
    position: absolute; }
  .text {
    position: absolute;
    max-width: 520px;
    &.label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content; }
    &.tl {
      top: 0;
      left: 0; }
    &.tr {
      top: 0;
      right: 0; }
    &.tc {
      top: 0; }
    &.bc {
      bottom: 0; }
    &.cr {
      right: 0; }
    &.cl {
      left: 0; }
    &.bl {
      left: 0;
      bottom: 0; }
    &.br {
      right: 0;
      bottom: 0; } }
  &.statePost {
    width: 490px;
    height: 870px; } }



.viewPost {
  width: 460px;
  height: 460px;
  min-width: 460px;
  min-height: 460px;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .poster {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media screen and ( max-width: 768px ) {
    margin-top: 25px;
    transform: scale(.7);
    .poster {
      width: 100%;
      height: auto;
      min-width: 100%;
      min-height: auto;
      aspect-ratio: 1/1; } } }

.statePost {
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: auto;
  aspect-ratio: 40/71;
  transform: scale(.75);
  .poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    .logo {
      width: 22%; } }
  @media screen and ( max-width: 768px ) {
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: auto;
    transform: scale(1); } }


.t-state {
  margin-top: -40px;
  @media screen and ( max-width: 768px ) {
    margin-top: 30px; } }






.logoPreview {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
  background-color: rgba(white,.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: contain; }
  button {
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $apple;
    border: none;
    margin: 10px; } }

.imgfloating {
  width: 100px;
  height: auto;
  object-fit: contain; }

.w-content {
  width: fit-content; }

.btn-central {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $green-dark2;
  width: fit-content;
  height: 40px;
  border-radius: 40px;
  padding: 0 20px;
  font-weight: bold;
  border: .5px solid $apple; }

.inputColor {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 4px 4px 0px $bg1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 100%;
    height: 100%;
    transform: scale(1.3); } }


.btn-link-step {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  text-decoration: none;
  background-color: $bg1;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  &:hover {
    color: $apple; }
  &.disabled {
    opacity: .3;
    pointer-events: none; } }

.btn-download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: $bg1;
  border: .5px solid $apple;
  border-radius: 10px;
  color: white;
  &::before {
    content: '';
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background: url('../img/dashboard/download_post.svg') center / 20px no-repeat; }
  &.loading {
    pointer-events: none;
    border: .5px solid $bg1;
    &::before {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: none;
      border: 2px solid $bg1;
      border-left: 2px solid $apple;
      animation: loadingEffect .6s linear infinite; } }
  @media screen and ( max-width: 768px ) {
    font-size: 18px; } }


@keyframes loadingEffect {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.footerPost {
  @media screen and ( max-width: 768px ) {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: calc( 100vw - 60px );
    background-color: $bg1;
    border-top: .5px solid $apple;
    margin: 0;
    button {
      height: 65px;
      font-size: 18px;
      border-radius: 0;
      &:nth-child(1) {
        border-left: .5px solid $apple !important; } } } }




.clamp-2, .clamp-3, .clamp-4, .clamp-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; }
.clamp-2 {
  -webkit-line-clamp: 2; }
.clamp-3 {
  -webkit-line-clamp: 3; }
.clamp-4 {
  -webkit-line-clamp: 4; }
.clamp-5 {
  -webkit-line-clamp: 5; }

