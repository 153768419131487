.loginHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  webkit-backdrop-filter: blur(5px);
  padding: 10px 0;
  .logo {
    width: 130px; }
  .store {
    transform: translateY(-7px); }
  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
    .link {
      padding: 0 23px;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($dark,.6);
      &:hover {
        color: $green-dark; } }
    .line {
      height: 10px;
      width: 1px;
      background: $green-dark;
      opacity: .4; }
    .btn-green {
      padding: 5px 30px; } }
  @media screen and ( max-width: 768px ) {
    nav {
      .link, .line {
        display: none; }
      .btn-green {
        padding: 5px 20px;
        font-size: 12px; } }
    .logo {
      width: 110px; } } }



.menuBtn {
  width: 37px;
  height: 37px;
  min-width: 37px;
  min-height: 37px;
  border-radius: 50%;
  border: .5px solid $green-dark;
  background: $orange-light url('../img/vector/menu.svg') center / 22px no-repeat;
  margin-left: 15px;
  pointer-events: auto;
  display: none;
  &:active , &:hover {
    background-color: white; }
  @media screen and ( max-width: 768px ) {
    display: block; } }

.float {
  animation: floating 5s ease-in-out infinite; }

@keyframes floating {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0px); } }


.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange-light;
  z-index: 20;
  pointer-events: none;
  img {
    width: 50px;
    opacity: .3; }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      background-color: $green-dark;
      height: 100%;
      width: calc( 100vw / 4 ); } } }


.transitionEffect {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  div {
    width: calc( 100vw / 4 );
    height: 100%;
    background-color: $green-dark;
    transform: -100%;
    position: absolute;
    transform: translateY(-100%);
    &:nth-child(1) {
      left: 0vw; }
    &:nth-child(2) {
      left: 25vw; }
    &:nth-child(3) {
      left: 50vw; }
    &:nth-child(4) {
      left: 75vw; } } }

.transitionEffectOut {
  position: fixed;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  div {
    position: relative;
    background-color: $green;
    width: calc( 100vw / 4 );
    height: 100%; } }

.PlegableMenuHome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dark, .green, .content {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: -100%; }
  .dark {
    background-color: $orange; }
  .green {
    background-color: $orange-light; }
  .content {
    background-color: $green-dark2;
    padding: 40px;
    text-align: center;
    .back {
      width: 110px;
      height: 110px;
      background-color: transparent;
      border: .5px solid $orange-light;
      border-radius: 50%;
      color: $orange-light;
      font-size: 14px;
      &:hover, &:active {
        background-color: $orange;
        border: .5px solid $orange; } }
    nav {
      margin-top: 40px;
      button {
        display: block;
        background-color: $orange-light;
        margin-bottom: 8px;
        padding: 20px 40px;
        opacity: 0;
        color: $green-dark;
        &:hover, &:active {
          background-color: $orange;
          color: $orange-light; } } } } }



.fadeOut {
  transform: translateY(100%) !important;
  opacity: 0 !important;
  pointer-events: none !important; }
.fadeUpOut {

  transform: translateY(-100%) !important;
  opacity: 0 !important;
  pointer-events: none !important; }



.btnGreen {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $green;
  color: $bg1;
  border-radius: 30px;
  padding: 10px 30px;
  &:hover {
    background-color: $orange; } }

.sub-text {
  text-align: start;
  font-size: 24px;
  strong {
    font-size: 24px; }
  @media screen and ( max-width: 768px ) {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    strong {
      font-size: 18px; } } }

.addEmptyProducts {
  background-color: $guide;
  padding: 30px;
  color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 15px;
  display: flex;
  position: relative;
  border: 2px solid white;
  animation: purpleBlur 1.6s ease-in-out 10;

  img {
    width: 80px;
    margin-right: 10px; }
  p, strong {
    font-size: 17px; }
  strong {
    color: $pink; }
  button {
    width: 30px;
    height: 30px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    margin: 10px;
    &::before, &::after {
      content: '';
      width: 18px;
      height: 3px;
      background-color: white;
      border-radius: 4px;
      position: absolute;
      transition: all .3s; }
    &::before {
      transform: rotate(-45deg); }
    &::after {
      transform: rotate(45deg); }
    &:hover {
      &::before, &::after {
        transform: rotate(0deg); } } }

  @media screen and ( max-width: 768px ) {
    padding: 20px;
    img {
      width: 60px; }
    p, strong {
      font-size: 18px; } } }

@keyframes purpleBlur {
  0% {
    box-shadow: 0 0 15px rgba($guide,0); }
  50% {
    box-shadow: 0 0 15px rgba($guide,1); }
  100% {
    box-shadow: 0 0 15px rgba($guide,0); } }


.welcomevideo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background-color: rgba($dark-purple,.4);
  backdrop-filter: blur(4px);
  .content {
    background-color: $bg1;
    border-radius: 24px;
    padding: 0 0 30px 0;
    width: 320px;
    border: .5px solid rgba($green,.1);
    position: relative;
    overflow: hidden; } }




.iframeVideo {
  height: auto;
  aspect-ratio: 1.08/2;
  border-radius: 12px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  .isLoadingVideo {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 50px;
      height: 50px;
      border: 5px solid white;
      border-left: 5px solid $guide;
      border-radius: 50%;
      animation: loadingVideo .7s linear infinite; } }
  iframe, video {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media screen and ( max-width: 768px ) {
    aspect-ratio: 1.065/2; } }



@keyframes loadingVideo {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.modalXXL {
  .modal-dialog {
    min-width: 700px;
    @media screen and ( max-width: 768px ) {
      min-width: auto;
      width: auto; } } }


.btnTikTok {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border: .5px solid white;
  height: 45px;
  width: calc( 100% - 15px );
  border-radius: 8px;
  padding-left: 10px;
  color: white;
  margin-top: 20px;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: url('../img/vector/tiktok_btn.svg') center / contain no-repeat; }
  @media screen and ( max-width: 980px ) {
    font-size: 0px;
    width: 100%;
    margin-top: 0;
    border-radius: 4px;
    border: .5px solid rgba(white,.2); } }

