.statistic {
  table {
    width: 100%;
    tr {
      border-bottom: .5px solid rgba($apple,.1);
      td {
        padding: 6px 4px;
        &:nth-child(1) {
          color: white; }
        &:nth-last-child(1) {
          text-align: right;
          width: 40%; } } } } }


.graphResumeSell {
  @media screen and ( max-width: 768px ) {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto; } }

.errorPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(black,.05);
  color: black; }


.chatBtn {
  display: inline-flex;
  align-items: center;
  background-color: $bg2;
  color: white;
  margin: 15px;
  border-radius: 6px;
  padding: 2px 2px 2px 10px;
  font-size: 14px;
  &::after {
    content: '';
    width: 28px;
    height: 28px;
    margin-left: 10px;
    background: white url('../img/vector/callcenter.svg') center / 24px no-repeat;
    border-radius: 6px; }
  &:hover {
    background-color: $aqua;
    color: black; }
  @media screen and ( max-width: 768px ) {
    font-size: 15px;
    padding: 2px 2px 2px 15px; } }


.scheduleContent {
  display: flex;
  gap: 7px;
  text-align: center;
  >div {
    padding: 10px;
    width: 100%;
    border: .5px solid rgba(white,.1);
    border-radius: 8px;
    h6 {
      color: $apple;
      border-bottom: .5px solid rgba($apple,.3);
      padding-bottom: 10px; } } }


.cardRaking {
  border: .5px solid rgba($apple,.3);
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  span {
    display: flex;
    width: 20px;
    text-align: center;
    font-family: $titleDash;
    font-size: 20px;
    color: $apple; }
  div {
    width: 100%;
    padding: 0 20px;
    p {
      color: white;
      margin: 0;
      line-height: 17px; }
    small {
      display: inline-flex;
      color: white;
      background-color: $guide;
      padding: 0 6px;
      border-radius: 4px; } }
  strong {
    display: block;
    width: 50px;
    text-align: center;
    color: $purple; } }



.select2-container--default .select2-selection--single {
  background-color: rgba(black,.1) !important;
  border: .5px solid rgba(white,.2) !important;
  width: 100%;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 20px 0 10px;
  border-radius: 10px !important;
  span {
    color: white !important;
    b {
      display: none; } }
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    background: url('../img/vector/acordion_arrow.svg') center / 14px no-repeat; } }


.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none; }


.select2-dropdown {
  background-color: $bg1 !important; }

.select2-search__field {
  background-color: $bg1;
  border: none !important;
  border-bottom: .5px solid $apple !important;
  border-radius: 0;
  padding: 10px !important;
  color: $apple; }

.select2-results__option {
  background-color: transparent;
  color: white; }
.select2-results__option[aria-selected="true"] {
  background-color: $apple !important;
  color: $bg1 !important; }

.select2-container--default .select2-results__option--selected {
  background-color: $bg2 !important;
  color: rgba(white, .6) !important; }

.contentSelect2 {
  .select2 {
    width: 100% !important; } }
