.inputSudo {
  select {
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    color: $apple;
    border: .5px solid $apple;
    width: 100%;
    &::placeholder {
      color: $apple; } } }
.linkMenu {
  display: flex;
  width: 30px;
  height: 30px;
  background: url('../img/dashboard/icon_link_order.svg') center / 20px no-repeat;
  border-radius: 4px;
  border: .5px solid $apple;
  margin-left: 8px; }

