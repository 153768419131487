.accKitchen {
  .accordion-item {
    background-color: $bg1;
    color: $orange-light;
    border: none;
    margin-bottom: 12px;
    border-radius: 7px;
    overflow: hidden;
    border: .5px solid $green-dark;
    .accordion-button {
      background-color: $bg1;
      color: $orange-light;
      border: none;
      border-radius: 7px;
      box-shadow: none;
      span {
        display: inline-flex;
        font-size: 11px;
        background-color: $apple;
        color: $bg1;
        border-radius: 4px;
        padding: 0 5px;
        margin-left: 10px;
        text-align: center;
        min-width: fit-content; }
      &::after {
        background: url('../img/dashboard/arrow_select.svg') center / contain no-repeat; }
      &.collapsed {} }
    .accordion-collapse {
      font-size: 14px;
      border-top: .5px solid $green-dark;
      table {
        width: 100%;
        tbody {
          tr {
            border-bottom: .5px solid rgba($apple, .4);
            td {
              &:nth-last-child(1) {
                text-align: end;
                color: $apple; } } } } } } } }

.btnSmall {
  display: inline-flex;
  align-items: center;
  border: none;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 5px;
  background-color: $bg2;
  min-width: fit-content;
  color: white;
  &::before {
    content: '';
    width: 25px;
    height: 25px;
    margin-right: 5px; }
  &:hover {
    color: white; }
  &.wspIcon {
    &::before {
      background: transparent url('../img/dashboard/icon_whatsapp.svg') center / 18px no-repeat; } }
  &.callClient {
    &::before {
      background: transparent url('../img/dashboard/call_client.svg') center / 15px no-repeat; } }
  &.linkOrder {
    &::before {
      background: transparent url('../img/dashboard/icon_link_order.svg') center / 17px no-repeat; } }
  @media screen and ( max-width: 768px ) {
    font-size: 14px; } }



.cardOrderResume {
  background-color: $bg1;
  padding-bottom: 8px;
  border-radius: 8px; }



.text-12 {
  font-size: 13px; }

.taksKitchen {
  li {
    width: 25% !important; } }


.btnDelete, .btnAccept, .btnAddOrder, .btnPrintTicket {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  &::before {
    content: '';
    width: 25px;
    height: 25px;
    margin-right: 10px;
    @media screen and ( max-width: 768px ) {
      width: 20px;
      height: 20px;
      margin-right: 5px; } } }


.btnDelete {
  background-color: $orange-dark;
  color: white;
  border: .5px solid $orange-dark;
  &::before {
    background: url('../img/dashboard/icon_delete.svg') center / 40px no-repeat; }
  &:hover {
    background-color: $error;
    border: .5px solid white; } }

.btnAccept {
  background-color: $green-dark;
  color: $bg1;
  border: .5px solid $green-dark;
  &::after {
    display: none; }
  &::before {
    background: url('../img/dashboard/check_step.svg') center / 24px no-repeat; }
  &:hover {
    background-color: $apple;
    border: .5px solid white; } }

.btnAddOrder {
  background-color: $bg2;
  color: white;
  border: .5px solid $green-dark;
  &::before {
    background: url('../img/dashboard/add_another_product.svg') center / 24px no-repeat; }
  &:hover {
    background-color: $dark-purple;
    border: .5px solid white; } }

.btnPrintTicket {
  background-color: $bg2;
  color: white;
  border: .5px solid $green-dark;
  &::before {
    background: url('../img/dashboard/print_icon.svg') center / 24px no-repeat; }
  &:hover {
    background-color: $dark-purple;
    border: .5px solid white; } }

.contactMSect {
  background-color: $bg1;
  padding: 6px;
  border-radius: 10px;
  margin-bottom: 40px;
  button,a {
    width: 100%;
    justify-content: center;
    height: 40px;
    font-size: 15px; }
  @media screen and ( max-width: 768px ) {
    button,a {
      margin: 0 !important;
      height: 60px; }
    .wspIcon {
      margin-bottom: 10px !important; } } }

.actionSection {
  height: auto;
  padding: 10px;
  background-color: rgba($bg2,.5);
  border-radius: 8px;
  a {
    box-shadow: 0 0 10px rgba(black,.1);
    width: 100%;
    height: 40px;
    justify-content: center; }
  @media screen and ( max-width: 768px ) {
    margin-top: 15px; } }


.tabsKitchen {
  @media screen and ( max-width: 768px ) {
    display: flex;
    border-radius: 0px;
    overflow-x: auto;
    padding: 4px 0;
    flex-wrap: nowrap;
    .nav-item {
      width: fit-content !important;
      white-space: nowrap;
      .nav-link {
        padding: 10px 25px !important;
        font-size: 13px !important;
        border-radius: 0px  !important; } } } }


.iconState {
  text-align: center;
  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-bottom: 10px; } }


.orderInMenu {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $bg2;
  transition: all .2s;
  opacity: 0;
  pointer-events: none;
  .ocShow {
    height: calc( 100vh - 70px );
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px; }
    &::-webkit-scrollbar-track {
      background-color: $bg1;
      border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 10px; }
    .headCategory {
      border-top: .5px dashed $green-dark;
      padding-top: 20px;
      h6 {
        color: $apple; } }
    .productList {
      color: white;
      border: .5px solid rgba(white,.1);
      background-color: $bg1;
      .content {
        h6 {
          color: $apple; }
        .bottom {
          .add {
            background-color: $green-dark2;
            color: white;
            border-radius: 7px;
            justify-content: center; } } } } }

  &.active {
    opacity: 1;
    pointer-events: auto; } }


.headerOrderInDashboard {
  position: sticky;
  top: 0;
  background-color: $bg1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: .5px solid $green;
  img {
    width: 45px; }
  h6 {
    text-align: center;
    margin: 0;
    color: white; }

  button {
    width: 50px;
    height: 50px;
    background-color: $orange;
    border: none;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
      content: '';
      width: 20px;
      height: 2px;
      background-color: white;
      border-radius: 3px;
      position: absolute;
      transition: all .2s; }
    &::before {
      transform: rotate(-45deg); }
    &::after {
      transform: rotate(45deg); }
    &:hover {
      &::before {
        transform: rotate(0deg); }
      &::after {
        transform: rotate(0deg); } } } }


.contentOrderP {
  .region {
    border: .5px solid $green-dark;
    background-color: $bg1;
    border-radius: 10px; }
  .counterProduct {
    border: .5px solid rgba($apple, .6);
    border-radius: 8px;
    background-color: $bg1;

    span {
      color: white; }
    button {
      color: white;
      &:active {
        background-color: $apple !important; } } }
  .groupMenu, .groupMenuCheck {
    background-color: rgba($bg2,.65);
    border-radius: 7px;
    padding: 12px;
    div {
      strong {
        color: white;
        margin-right: 30px;
        &::after {
          border: .5px solid $apple;
          right: 0; }
        &::before {
          font-size: 0;
          right: 6px; } }
      input {
        &:checked ~ strong {
          color: $apple;
          &::before {
            background-color: $apple;
            width: 20px;
            height: 20px;
            right: 5px; } } } } }
  .groupMenuCheck {
    div {
      input {
        &:checked ~ strong {
          &::before {
            background-color: transparent; } } } } }

  .modalComments {
    border-radius: 10px;
    border: .5px solid $green-dark;
    color: white;
    background-color: $bg1;
    &::placeholder {
      color: rgba(white,.5);
      font-size: 15px; } }

  .prices {
    margin-bottom: 15px;
    padding-bottom: 10px;
    .after {
      color: $apple;
      font-size: 18px; }
    .before {
      font-size: 15px;
      color: $gold;
      text-decoration: line-through;
      display: inline-flex;
      margin-right: 10px; } }
  .addModal {
    width: 100%;
    background-color: $bg1 !important;
    border: .5px solid $apple !important;
    color: $apple;
    &:hover {
      background-color: $apple !important;
      color: $bg1 !important; }
    &.disabled {
      background-color: $bg2;
      border: .5px solid $bg2;
      opacity: .4;
      pointer-events: none; }
    @media screen and ( max-width: 768px ) {
      width: 43vw;
      margin: 0;
      padding: 20px 15px; } } }




.cardOrderInMenu {
  border-bottom: .5px dashed rgba($apple,.3);
  padding: 10px 0;
  .counterProduct {
    border: .5px solid $apple;
    width: 150px;
    padding: 4px;
    button {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center; }
    span {
      font-size: 15px; } } }

.totalPriceInOrder {
  border-top: .5px solid $apple;
  display: flex;
  strong {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 13px;
    font-size: 12px;
    &:nth-child(1) {}

    &.price {
      background-color: rgba($green,.03);
      font-size: 17px;
      border-radius: 0 0 15px 15px; } } }

.emptyCarInDash {
  text-align: center;
  padding: 40px 0;
  border: .5px dotted rgba($apple, .3);
  border-radius: 10px;
  background-color: $bg2; }

.contentProductsInDash {
  max-height: calc( 100vh - 290px );
  overflow-y: auto;
  &.active {
    padding: 20px;
    background-color: rgba(black,.03);
    border-radius: 8px; }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  &::-webkit-scrollbar-track {
    background-color: $bg2;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 10px; }
  @media screen and ( max-width: 768px ) {
    max-height: calc( 100vh - 330px ); } }


.labelChilli {
  display: flex;
  justify-content: center;
  align-items: center;
  border: .5px solid $orange-dark;
  background-color: white;
  color: $orange-dark;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 4px 10px;
  border-radius: 5px;
  svg {
    fill: $orange-dark; } }

.modalPrintOrder {
  .modal-header {
    position: sticky;
    top: 0;
    background: $bg1;
    z-index: 1;
    div {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      width: 100%; } } }

.printBtn, .shareBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    width: 28px;
    height: 28px;
    margin-right: 6px; } }

.printBtn {
  &::before {
    background: url('../img/dashboard/print_white.svg') center / 24px no-repeat; }
  &:hover {
    &::before {
      background: url('../img/dashboard/print_dark.svg') center / 24px no-repeat; } } }

.shareBtn {
  &::before {
    background: url('../img/dashboard/wsp_white.svg') center / 24px no-repeat; }
  &:hover {
    &::before {
      background: url('../img/dashboard/wsp_dark.svg') center / 24px no-repeat; } } }


.colOrderPending {
  @media screen and ( max-width: 768px ) {
    background-color: rgba(black,.3);
    border-bottom: .5px dashed $apple;
    padding-top: 10px;
    padding-bottom: 10px;
    p.text-apple {
      text-align: center; }
    .accordion-item {
      border: .5px solid $gold;
      box-shadow: 2px 2px 0px $gold, -2px -2px 0px $apple; } } }

.labelReq, .labelOp {
  display: inline-flex;
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 13px; }

.labelReq {
  background-color: $orange; }
.labelOp {
  background-color: $green;
  color: $bg1; }

.footerMobileOrderInMenu {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 85px;
  border-radius: 10px 10px 0 0;
  background: $bg1;
  justify-content: center;
  align-items: center;
  border-top: .5px solid $green;
  border-left: .5px solid $green;
  border-right: .5px solid $green;
  display: none;
  padding: 10px;
  button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $apple;
    color: $bg1;
    font-size: 20px; }

  @media screen and ( max-width: 768px ) {
    display: flex; } }


.alertAqua {
  border: 1px solid $aqua;
  background-color: rgba($aqua,.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  &.danger {
    border: 1px solid $guide;
    background-color: rgba($guide,.1); }
  @media screen and ( max-width: 768px ) {
    display: block;
    margin-bottom: 10px;
    button {
      margin-top: 20px;
      width: 150px; } } }



