.qrCode {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  @media screen and ( max-width: 768px ) {
    width: 200px !important;
    height: 200px !important;
    padding: 10px; } }


.copybox {
  background-color: $bg1;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 15px;
  justify-content: space-between;
  margin-top: 20px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    input {
      width: 100%;
      background-color: transparent;
      color: white;
      border: none;
      pointer-events: none;
      &::selection {
        background-color: $bg1;
        color: white; } }
    strong {
      font-size: 13px; } }
  button {} }

//calculator-------------------

.calculatorContent {
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(black,.1);
  border-top: .5px solid rgba($green-dark,.3);
  border-right: .5px solid rgba($green-dark,.3);
  border-bottom: .5px solid rgba($orange,.3);
  border-left: .5px solid rgba($orange,.3);
  button, input {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg1;
    margin-bottom: 8px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    color: $orange-light; }
  button {
    &.equal {
      margin-top: 20px;
      background: $green;
      &:active {
        background-color: $green-dark; } }
    &.clearAll {
      background: $orange;
      &:active {
        background-color: $pink; } }
    &:active {
      background-color: $green-dark; }
    &.disabled {
      pointer-events: none;
      background: $bg1;
      opacity: .5; } }

  input {
    text-align: center;
    padding: 5px 30px;
    font-size: 30px;
    height: 70px;
    margin-bottom: 20px;
    &.error {
      color: $orange;
      font-size: 17px; } }
  @media screen and ( max-width: 768px ) {
    padding: 10px;
    border: none;
    border-radius: 10px;
    .col-3, .col-12, .col-6 {
      padding: 0 10px; }
    input {
      margin-bottom: 40px;
      font-size: 20px; }
    button {
      margin-bottom: 10px; } } }


