.contentMainSlider {
  padding: 6px;

  @media screen and ( max-width: 768px ) {
    padding: 5px 0; } }


.mainCards {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  .icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    margin: 8px;
    filter: drop-shadow(0 0 3px rgba($t4,.6)); }
  .label {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: $c3;
    color: $c4;
    font-size: 11px;
    border-radius: 5px;
    padding: 0 7px;
    transition: all .2s;
    z-index: 1;
    pointer-events: none; }
  .content, .cc {
    pointer-events: none;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/.7;
    max-height: 200px;
    img {
      transition: all .2s;
      width: 100%;
      /* min-width: 400px */
      min-height: 170px;
      height: 100%;
      object-fit: cover; }
    .overlay {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 7px;
      width: 100%;
      background: linear-gradient(0deg, rgba($t4,.3), rgba($t4,0));
      h6 {
        width: 100%;
        text-align: start;
        color: $c3;
        text-shadow: 0 0 3px $c4;
        padding-left: 10px; }
      .plus {
        width: 42px;
        min-width: 42px;
        height: 42px;
        background-color: $c3;
        border-radius: 7px;
        transition: all .2s;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after, &::before {
          content: '';
          width: 17px;
          height: 2px;
          background-color: $c4;
          position: absolute; }
        &::before {
          rotate: 90deg; } } } }
  &:hover {
    .content, .cc {
      img {
        transform: scale(1.1); }
      .plus {
        background-color: $c3;
        &::after, &::before {
          background-color: $c1; } } } } }



.headCategory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 23px 0;
  h6 {
    color: $c1;
    white-space: nowrap; }
  span {
    display: inline-flex;
    white-space: nowrap;
    height: 25px;
    font-size: 11px;
    border-radius: 5px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    background: $c3;
    color: $c4;
    box-shadow: 0 0 6px rgba($t4,.1);
    margin: 0 10px;
    font-weight: bold; }
  div {
    width: 100%;
    height: 1px;
    border-top: 2px dashed rgba($t4,.05); } }



.productList {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba($t4,.08);
  border-radius: 10px;
  transition: all .2s;
  border: .5px solid $c3;
  width: 100%;
  overflow: hidden;
  background-color: $c3;
  .favorite {
    width: 24px;
    height: 24px;
    margin-right: 6px; }
  .img {
    width: auto;
    max-width: 150px;
    min-width: 150px;
    height: auto;
    aspect-ratio: 1/.85;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    .label {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 11px;
      display: inline-flex;
      padding: 0 5px;
      border-radius: 3px;
      background-color: $c3;
      color: $c4;
      margin: 4px; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s; } }
  .content {
    width: calc( 100% - 150px );
    height: 100%;
    text-align: start;
    padding-left: 10px;
    display: flex;
    color: $c4;
    flex-direction: column;
    justify-content: space-center;
    .top, .bottom {
      width: 100%; }
    h6 {
      font-size: 15px;
      margin-bottom: 7px;
      color: $c4; }
    p {
      font-size: 13px;
      line-height: 15px;
      opacity: .8;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 30px; }
    .prices {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
      padding-right: 20px;
      .before {
        color: $c4;
        opacity: .4;
        font-size: 12px;
        span {
          text-decoration: line-through; } }
      .after {
        color: rgba($t4,1);
        font-size: 13px; } }
    .add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .icon {
        width: 37px;
        height: 37px;
        padding: 5px; }
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 37px;
        background-color: $c3;
        border-radius: 8px;
        transition: all .2s;
        border: .5px solid rgba($t4, .04); } } }
  &:hover {
    box-shadow: none;
    border: .5px solid rgba($t2, .5);
    transform: scale(1.04);
    .img {
      img {
        scale: 1.1; } }
    .content {
      .add {
        span {
          background-color: $c2;
          color: $c3;
          border: .5px solid $c2; } } } }
  @media screen and ( max-width: 768px ) {
    .content {
      .add {
        span {
          background-color: rgba($t2,.06);
          border: none; } }
      .prices {
        .after {
          color: $c2; } } } } }




.searchBread {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 20px;
  background-color: $c2;
  color: $c3;
  border-radius: 8px;
  span {
    color: $c3;
    font-size: 18px; }

  button {
    width: 35px;
    height: 35px;
    border: .5px solid rgba($t3,.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    border-radius: 6px;
    position: relative;
    &::before, &::after {
      content: '';
      width: 10px;
      height: 2px;
      background-color: $c3;
      position: absolute;
      transition: all .2s; }
    &::before {
      transform: rotate(-45deg); }
    &::after {
      transform: rotate(45deg); }
    &:hover {
      &::before, &::after {
        transform: rotate(0deg); } } } }

.noFound {
  background-color: rgba($t2,.03);
  padding: 40px;
  border-radius: 10px;
  color: $c4;
  button {
    color: rgba($t2, .8);
    background-color: transparent;
    display: inline-flex;
    padding: 2px 10px;
    border-radius: 4px;
    text-decoration: underline;
    @media screen and ( max-width: 768px ) {
      margin-top: 20px; } } }

#showMProduct {
  backdrop-filter: blur(3px);
  .modal-dialog {
    max-width: 90vw !important;
    min-height: calc( 100vh - 150px );
    .modal-content {
      background-color: $c3;
      border: .5px solid rgba($t2,.15);
      .modal-header {
        border-bottom: .5px solid rgba($t4, .1);
        position: relative;
        background-color: $c3;
        z-index: 2;
        h1 {
          color: $c4;
          margin: 0;
          .cat {
            display: inline-flex;
            align-items: center;
            font-size: 13px;
            margin-right: 7px;
            background-color: $c2;
            color: $c3;
            padding: 2px 9px;
            border-radius: 4px;
            font-family: $nunito; } }
        @media screen and ( max-width: 768px ) {
          position: sticky;
          top: 0; } }




      .modal-body {
        color: $c4;
        font-family: $nunito;
        .icon, .favorite {
          min-width: 35px;
          width: 35px;
          height: 35px;
          border-radius: 0; }
        p {
          font-size: 15px; }
        img {
          border-radius: 10px;
          width: 100%;
          padding: 0;
          position: relative; }
        .imagePreview {
          position: sticky;
          top: 0; }

        .label {
          display: inline-flex;
          justify-content: center;
          width: 100%;
          max-width: 90px;
          padding: 3px 8px;
          border-radius: 5px;
          font-size: 13px;
          color: $c3;
          &.req {
            background-color: $c1; }
          &.op {
            background-color: $c2; } }

        .prices {
          display: flex;
          align-items: center;
          margin-top: 6px;
          margin-bottom: 20px;
          .before {
            color: $c4;
            opacity: .5;
            font-size: 14px;
            margin-right: 20px;
            span {
              text-decoration: line-through; } }
          .after {
            color: rgba($t4,1);
            font-size: 23px;
            font-family: $nunito; } } }

      .modal-footer {
        position: sticky;
        bottom: 0;
        border-top: .5px solid rgba($t4, .1);
        background-color: $c3;
        z-index: 1; } } }
  @media screen and ( max-width: 768px ) {
    .modal-dialog {
      max-width: 100vw !important;
      margin: 0;
      .modal-content {
        margin: 0;
        .modal-header {
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          z-index: 2; }
        .modal-body {
          border: none;
          min-height: calc( 100vh - 163px );
          border-radius: 0;
          padding: 0;
          .imagePreview {
            position: relative; }
          p {
            font-size: 16px; }
          img {
            border-radius: 0; } }
        .modal-footer {
          z-index: 2;
          border-radius: 0; } } } } }

.btn-close {
  opacity: 1;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
    box-shadow: none; }
  &::before, &::after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: $c4;
    position: absolute;
    transition: all .2s; }
  &::before {
    transform: rotate(-45deg); }
  &::after {
    transform: rotate(45deg); }
  &:hover {
    &:before, &::after {
      background-color: $c2;
      transform: rotate(0deg); } } }

.counterProduct {
  border: .5px solid rgba($t4, .2);
  border-radius: 6px;
  padding: 3px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 20px;
    color: $c4; }
  button {
    width: 45px;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 6px;
    background-color: rgba($t4,0);
    color: $c4;
    font-size: 24px;
    &:active {
      background-color: $c2 !important;
      color: $c3; }
    &:hover {
      background-color: rgba($t2,.2); } }
  @media screen and ( max-width: 768px ) {
    width: 43vw;
    margin: 0;
    button {
      width: 50px; } } }


.region {
  background-color: rgba($t4,.02);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
  @media screen and ( max-width: 768px ) {
    padding: 50px 10px;
    border-top: .5px dashed rgba($t4,.3);
    border-radius: 0; } }


.lineBtnModal {
  font-size: 15px;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  padding: 14px 15px;
  background-color: $c3;
  border: 2px solid $c2;
  color: $c2;
  &.disabled {
    border: 2px solid $c4;
    color: $c4;
    opacity: .4;
    pointer-events: none; }
  @media screen and ( max-width: 768px ) {
    width: 43vw;
    margin: 0;
    padding: 20px 15px; } }

.addModal {
  font-size: 15px;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  padding: 14px 15px;
  background-color: $c2;
  border: .5px solid $c2;
  color: $c3;
  &.disabled {
    background-color: $c4;
    border: .5px solid $c4;
    opacity: .4;
    pointer-events: none; }
  @media screen and ( max-width: 768px ) {
    width: 43vw;
    margin: 0;
    padding: 20px 15px; } }


.groupMenu, .groupMenuCheck {
  padding: 30px 60px 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  background-color: $c3;
  border-radius: 6px;
  margin-top: 10px;
  span {
    display: block;
    width: 60%; }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    strong {
      display: flex;
      align-items: center;
      padding-right: 10px;
      font-size: 14px;
      color: $c4;
      &::after, &::before {
        content: '';
        pointer-events: none;
        border-radius: 50%;
        transition: all .2s;
        position: absolute; }
      &::after {
        width: 30px;
        height: 30px;
        border: .5px solid $c2;
        right: -40px; }
      &::before {
        width: 17px;
        height: 17px;
        background-color: $c2;
        right: -33.5px;
        transform: scale(0); } }
    input {
      display: none;
      &:checked ~ strong {
        color: $c2;
        &::before {
          transform: scale(.9); } } } }
  @media screen and ( max-width: 768px ) {
    span {
      font-size: 18px;
      line-height: 20px; }
    div {
      strong {
        font-size: 15px; } } } }




.groupMenuCheck {
  div {
    strong {
      &::after {
        border-radius: 5px; }
      &::before {
        content: '+';
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 0;
        color: $c2;
        background-color: transparent;
        transform: none;
        z-index: 1; } }
    input {
      display: none;
      &:checked ~ strong {
        color: $c2;
        &::before {
          content: '';
          background: url('../../img/menu/checked_menu.svg') center / 15px no-repeat; }
        &::after {
          background-color: $c2; } } } } }


.modalComments {
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  border: .5px solid $c4;
  color: $c4;
  background-color: $c3;
  &::placeholder {
    color: rgba($t4,.5);
    font-size: 15px; }
  &:focus {
    border: .5px solid $c2; }
  @media screen and ( max-width: 768px ) {
    font-size: 17px; } }


.favorite {
  width: 35px;
  height: 35px;
  margin-left: 7px;
  padding: 0;
  border: none;
  background-color: transparent;
  svg {
    width: 100%;
    height: 100%; } }



.disabledCheck {
  opacity: .4;
  pointer-events: none; }



.icon-sm {
  width: 40px;
  height: 40px;
  object-fit: contain; }
