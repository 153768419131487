
@font-face {
  font-family: 'nunito';
  src: url('../fonts/Nunito-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal; }


$c1: var(--c1);
$c2: var(--c2);
$c3: var(--c3);
$c4: var(--c4);
$t1: var(--t1);
$t2: var(--t2);
$t3: var(--t3);
$t4: var(--t4);
//----
$nunito : 'nunito';


body {
  background-color: $c3 !important; }


.wrappeContent {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  color: $c4;
  font-family: $nunito;
  font-size: 15px;
  background-color: rgba($t3,.3);
  display: flex;

  aside {
    width: 390px;
    margin: 0;
    padding: 0; }
  main {
    height: 100vh;
    width: calc( 100vw - 390px );
    overflow-y: auto;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    &::-webkit-scrollbar-track {
      background-color: $c3;
      border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
      background-color: $c4;
      border-radius: 10px; } }
  @media screen and ( max-width: 768px ) {
    display: block;
    main {
      width: 100vw;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px; } }
    aside {
      width: 100vw;
      min-height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: $c3;
      z-index: 10;
      transform: translateX(100%);
      transition: all .2s;
      &.active {
        transform: translateX(0%); } } } }






.headerMenu {
  position: fixed;
  top: 0;
  z-index: 3;
  background-color:  $c3;
  backdrop-filter: blur(12px);
  border-bottom: .5px solid $c3;
  width: calc( 100vw - 390px );
  .content {
    padding: 7px;
    border-radius: 10px; }
  .logo {
    width: 125px;
    height: auto;
    aspect-ratio: 2/1;
    border-radius: 15px;
    overflow: hidden;
    padding: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }
  @media screen and ( max-width: 768px ) {
    padding: 8px 5px 8px 0;
    width: 100vw;
    .logo {
      padding: 0;
      border-radius: 0; }
    .searchContent {
      background: $c3;
      border-radius: 8px;
      input {
        padding: 6px 10px;
        height: 30px;
        font-size: 14px; } } } }

.minAmount {
  background-color: rgba($t4,.06);
  padding: 6px;
  text-align: center;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  small {
    display: inline-flex;
    line-height: 7px; }
  strong {
    display: block;
    line-height: 18px;
    margin-top: 3px;
    letter-spacing: 1px; }
  @media screen and ( max-width: 768px ) {
    border: .5px solid rgba($t1,.4);
    height: 70px;
    background-color: rgba($t1,.06);
    small {
      line-height: 16px;
      font-size: 16px; }
    strong {
      font-size: 20px; } } }


.menuOptions {
  width: 50px;
  height: 50px;
  border: none;
  margin-right: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  div {
    transition: all .3s;
    width: 23px;
    height: 2px;
    border-radius: 1px;
    background-color: $c4;
    &:nth-child(1) {
      width: 14px; }
    &:nth-child(2) {
      width: 19px;
      margin: 5px 0; } }
  @media screen and ( max-width: 768px ) {
    div {
      height: 3px; } } }





.searchContent {
  padding: 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: rgba($t3,.6);
  border: .5px solid rgba($t4,.6);
  input {
    height: 38px;
    width: 100%;
    color: $c4;
    border: none;
    padding: 0 15px;
    background-color: transparent;
    &::placeholder {
      color: $c4; } } }


.showCategories {
  position: sticky;
  top: 77px;
  z-index: 2;
  overflow-x: auto;
  display: flex;
  align-items: center;
  background-color: $c3;
  border-bottom: .5px solid $c4;
  border-top: .5px solid $c4;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0;
    height: 0; }
  a, button {
    width: fit-content;
    padding: 7px 30px;
    border-radius: 0;
    background: $c3;
    white-space: nowrap;
    color: $c4;
    font-size: 13px;
    &.active {
      background-color: rgba($t1,.08);
      color: $c1; } }
  &.disabledC {
    button {
      opacity: 0;
      pointer-events: none; } }
  @media screen and ( max-width: 768px ) {
    padding: 0;
    top: 77px;
    background-color: $c4;
    a,button {
      background-color: $c3;
      color: $c4;
      font-size: 14px;
      padding: 10px 20px;
      margin: 0;
      border-radius: 0;
      border-left: .5px solid $c3;
      &.active {
        background-color: $c1;
        color: $c3; } } } }


.menuLoading {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: all .1s;
  background-color: $c3;
  &.disabled {
    opacity: 0;
    pointer-events: none; } }




@keyframes fooderBlur {
  0% {
    box-shadow: 0 0 0 rgba($t2 , 0); }
  25% {
    box-shadow: 0 0 35px rgba($t2 , .7); }
  50% {
    box-shadow: 0 0 0 rgba($t2 , 0); }
  75% {
    box-shadow: 0 0 35px rgba($t1 , .7); }
  100% {
    box-shadow: 0 0 0 rgba($t2 , 0); } }




.footerMenu {
  background: rgba($t3,.97);
  color: $c4;
  border-top: .5px solid $c4;
  padding: 40px 0;
  .socialLink {
    display: flex;
    width: 40px;
    height: 40px;
    border: .5px solid $c4;
    margin-left: 6px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    svg {
      width: 25px;
      height: 25px;
      object-fit: contain; }
    .cls-1 {
      fill: $c4; }
    &:hover {
      border: .5px solid $c2;
      background-color: $c2;
      .cls-1 {
        fill: $c3; } } }
  @media screen and ( max-width: 768px ) {
    padding-bottom: 110px; } }


.plegableMenuClient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  background-color: rgba($t3,.3);
  backdrop-filter: blur(2px);
  transition: .1s;
  opacity: 0;
  pointer-events: none;
  .footerMenu {
    background-color: $c3; }
  hr {
    border-top: .5px solid $c4;
    opacity: 1;
    margin: 0; }
  .panel {
    transition: .1s;
    width: 380px;
    min-width: 380px;
    height: 100vh;
    min-height: 500px;
    overflow-y: auto;
    background-color: $c3;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: .5px solid $c4;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px; }
    .logo {
      width: 130px;
      margin-bottom: 30px !important; }
    .nav-tabs {
      border-bottom: none; }
    nav {
      ul {
        margin: 0;
        padding: 0;
        background-color: transparent;
        li {
          background-color: transparent;
          display: block;
          width: 100%;
          button {
            display: block;
            border: none;
            height: 60px;
            width: 100%;
            color: $c4;
            background: transparent;
            border-radius: 0;
            transition: all .2s;
            letter-spacing: 1px;
            &.active {
              background-color: rgba($t4,.07);
              border-radius: 0;
              color: $c4;
              letter-spacing: 2px; }
            &:hover {
              background-color: $c4;
              color: $c3;
              letter-spacing: 2px; } } } } } }
  .overlay {
    width: 100%;
    height: 100%; }
  &.active {
    opacity: 1;
    pointer-events: auto;
    .panel {
      transform: translateX(0%); } }
  @media screen and ( max-width: 768px ) {
    .panel {
      width: 85vw;
      min-width: 85vw;
      .logo {
        width: 120px; } } } }




.footerOrder {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  background-color: $c3;
  border-top: .5px solid $c4;
  display: none;
  .fooderAd, .OrderBtn {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c4;
    svg {
      width: 32px;
      height: 32px; }
    span {
      display: inline-flex;
      margin-left: 10px; }
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $c2;
      color: $c3;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      margin-left: 10px; } }
  .fooderAd {
    border-right: .5px solid $c4;
    strong {
      color: $c4; } }
  @media screen and ( max-width: 768px ) {
    display: block; } }


.hover-c1 {
  &:hover {
    color: $c1; } }
.hover-c2 {
  &:hover {
    color: $c2; } }
.hover-c3 {
  &:hover {
    color: $c3; } }
.hover-c4 {
  &:hover {
    color: $c4; } }




.adFooter {
  background-color: $c1;
  transition: all .2s;
  a,button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    span {
      color: $c3; }
    strong {
      display: inline-flex;
      padding: 4px 15px;
      background-color: $c4;
      color: $c3;
      border-radius: 6px;
      margin-left: 7px;
      transition: all .2s; } }
  &:hover {
    background-color: $c4;
    strong {
      background-color: $c2;
      color: $c3; } } }


.adArea {
  position: sticky;
  width: 260px;
  bottom: 0;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background-color: $c3;
    border-radius: 0 10px 0 0;
    padding: 7px 20px;
    border-top: .5px solid $c4;
    border-right: .5px solid $c4;
    span {
      color: $c4;
      display: inline-flex;
      margin-left: 10px;
      font-size: 13px; } } }


.loadMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: .2s;
  z-index: 10000000;
  background-color: $c3;
  pointer-events: none;
  opacity: 0;
  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid rgba($t4,.1);
    border-left: 4px solid $c2;
    animation: none; }
  &.active {
    opacity: 1;
    pointer-events: auto;
    div {
      animation: loadMenuEffect .6s linear infinite; } } }
@keyframes loadMenuEffect {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.orderPending {
  position: sticky;
  top: 112px;
  right: 0;
  z-index: 2;
  padding: 15px 0;
  background-color:  $c3;
  border-top: .5px solid $c4;
  border-bottom: .5px solid $c4;
  backdrop-filter: blur(10px);
  h5 {
    color: $c4 !important; }
  a {
    &:hover {
      color: $c3; } }

  width: 100%;
  margin-bottom: 20px;
  @media screen and ( max-width: 768px ) {
    top: 120px;
    padding: 10px; } }



.almostSent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba($t4,.3);
  backdrop-filter: blur(5px);
  opacity: 0;
  pointer-events: none;
  .content {
    width: 400px;
    max-width: 400px;
    background-color: $c3;
    color: $c4;
    border-radius: 15px;
    text-align: center;
    padding: 50px;
    transition: all .2s;
    transition-delay: .2s;
    transform: translateY(60px);
    a, button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c2;
      padding: 20px;
      border-radius: 10px;
      span {
        color: $c3;
        font-size: 17px; }
      svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        path {
          fill: $c3; } } } }
  &.active {
    opacity: 1;
    pointer-events: auto;
    .content {
      transform: translateY(0); } }
  @media screen and ( max-width: 768px ) {
    padding: 30px;
    .content {
      width: 100%;
      padding: 30px; } } }

.closedMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  div {
    width: 400px;
    height: auto;
    padding: 30px;
    backdrop-filter: blur(20px);
    border: 2px solid $c2;
    border-radius: 24px;
    text-align: center;
    box-shadow:  10px 10px 5px rgba($t4,.1);
    background: linear-gradient(0deg, $c3 ,rgba($t2,.25));
    img {
      width: 150px; }
    strong {
      display: block;
      margin-top: 30px;
      color: $c4; } }
  @media screen and ( max-width: 768px ) {
    padding: 20px;
    div {
      width: 100%;
      strong {
        font-size: 20px; } } } }

.contentListTYC {
  div {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba($t2,.1);
    strong {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $c2;
      font-size: 20px;
      color: $c3;
      margin-right: 15px; }
    p {
      width: 100%; } } }

.arrowBackMenu {
  display: flex;
  align-items: center;
  svg {
    width: 14px;
    margin-right: 10px; } }

.btnShare {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border: .5px solid $c4;
  margin-left: 7px;
  border-radius: 50%;
  padding: 0;
  svg {
    fill: $c4;
    padding: 12px; }
  &:hover {
    background-color: $c4;
    svg {
      fill: $c3; } }
  @media screen and ( max-width: 768px ) {
    border: none;
    width: 35px;
    min-width: 35px;
    height: 35px;
    svg {
      padding: 5px; } } }

.reserveBold {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  #payment-section {
    z-index: 1; }
  .load {
    width: 30px;
    height: 30px;
    border: 4px solid $c2;
    border-radius: 50%;
    position: absolute;
    border-left-color: $c3;
    animation: loading .7s linear infinite;
    @keyframes loading {
      0% {
        transform: rotate(0deg); }
      100% {
        transform: rotate(360deg); } } } }





