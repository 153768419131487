.stepsSettings {
  border: .5px solid $w40;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 0;
  section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px; }
      span {
        font-size: 13px;
        display: block; } }
    .line {
      width: 1px;
      height: 30px;
      background-color: $w40; } }
  .progress {
    height: 5px;
    margin-top: 10px;
    background-color: $bg1;
    border-radius: 3px;
    .progress-bar {
      border-radius: 3px;
      background-color: $green-dark;
      min-width: 10%; } }
  @media screen and ( max-width: 800px ) {
    section {
      div {
        flex-direction: column;
        align-items: center;
        span {
          text-align: center;
          line-height: 13px;
          margin-top: 10px; }
        img {
          width: 30px;
          height: 30px; } }
      .line {
        height: 70px; } } }
  @media screen and ( max-width: 450px ) {
    border-radius: 8px;
    padding: 8px;

    section {
      width: 100%;
      div {
        width: 25%;
        height: 40px;
        img {
          margin: 0; }
        span {
          display: none; } }
      .line {
        display: none; } } } }





.footerSettings {
  position: relative;
  bottom: 0;
  height: 60px;
  background-color: $bg1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: calc( 100% - 210px );
  border-top: .5px solid $green-dark;
  .next,.prev,.save {
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $w40;
    &:hover, &:active {
      background-color: $green-dark;
      color: white; } }
  .next {}
  .prev {}
  .save {
    pointer-events: none;
    &.active {
      pointer-events: auto;
      background-color: $green; } }
  @media screen and ( max-width: 1000px ) {
    width: calc( 100% - 60px ); } }



.slideSettings {
  width: calc( 100vw - 250px );
  margin: 0;

  @media screen and ( max-width: 1000px ) {
    width: calc( 100vw - 84px ); } }


.inputDash {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: $bg1;
  border-radius: 6px;
  input, select {
    background-color: $bg1;
    padding: 10px;
    height: 50px;
    width: 100%;
    border: none;
    -webkit-appearance: none;
    color: $orange-light;
    &::placeholder {
      color: rgba(white,.4); } }
  &.disabled {
    opacity: .5;
    pointer-events: none; } }

.inputSearch {
  border-radius: 6px;
  background-color: $bg1;
  padding: 10px 20px;
  height: 50px;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  color: $orange-light;
  &.disabled {
    cursor: not-allowed; } }

.pac-container {
  .pac-footer {
    display: none; } }


.sortTYC {
  border: .5px solid rgba(white,.3);
  p {
    padding: 10px 0; }
  @media screen and ( max-width: 768px ) {
    display: block !important;
    .info {
      padding: 0 10px !important; }
    .options {
      margin: 0 !important; } } }



.inputDashDouble {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: $bg1;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px 20px;
  span {
    color: white; }
  input {
    background-color: transparent;
    width: 100%;
    border: none;
    color: $orange-light;
    margin-left: 10px;
    &::placeholder {
      color: rgba(white,.4); } } }



//------------------HORARIOS DE ATENCIÓN-------------------------//
.disabledField {
  pointer-events: none;
  input[type="time"] {
    filter: opacity(.5); }
  .btnGreen {
    opacity: .5; } }

.daySchedule {
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 14px;
  color: $bg1;
  min-height: 325px;
  label.pr {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: .5px solid rgba($bg1,.1);
    padding: 10px 0;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
      font-size: 15px;
      &.text-green {
        width: fit-content;
        font-size: 12px; } } }
  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    filter: hue-rotate(206deg) brightness(.98); }
  input[type="time"] {
    display: block;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: none;
    margin-bottom: 10px;
    padding: 6px 20px;
    border: .5px solid $green;
    background-color: rgba(black,.04) !important;
    &::-webkit-datetime-edit {
      font-size: 20px; }
    &:nth-last-child(1) {
      margin-bottom: 0; } }
  @media screen and ( max-width: 768px ) {
    margin-bottom: 8px;
    min-height: auto;
    label.pr {
      span {
        font-size: 20px; } } } }




.contentPriceDash {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bg2;
  padding: 20px;
  border-radius: 20px;
  input {
    height: 50px;
    width: 200px;
    margin: 0 20px;
    border-radius: 10px;
    border: .5px solid $apple;
    color: $apple;
    background-color: transparent;
    padding: 0 20px;
    font-size: 20px; }
  @media screen and ( max-width: 768px ) {
    input {
      width: 100%; } } }


.footerSettingsDash {
  width: 300px;
  position: fixed;
  bottom: 9px;
  right: calc(50% - ((300px + 210px)/2));
  display: flex;
  justify-content: space-between;
  background: $bg1;
  padding: 10px;
  border: .5px solid rgba(white,.2);
  border-radius: 10px;
  button {
    border: .5px solid white;
    width: 100%;
    height: 70px;
    border-radius: 6px;
    position: relative;
    display: flex;
    &.prev {
      background: $bg1 url('../img/dashboard/arrow_prev_step.svg') center / 60px no-repeat; }
    &.next {
      background: $bg1 url('../img/dashboard/arrow_next_step.svg') center / 60px no-repeat; }
    &.disabled {
      opacity: .2;
      pointer-events: none; } }
  &.stepGuide {
    z-index: 100;

    button {
      &.prev {
        opacity: .5;
        pointer-events: none; }
      &.next {
        border: .5px solid $green;
        background: $green url('../img/dashboard/arrow_next_step.svg') center / 60px no-repeat;
        animation: pulseNext 2s ease-in-out infinite; } } }

  @media screen and ( max-width: 768px ) {
    width: calc( 100% - 60px );
    bottom: 0;
    right: 0;
    justify-content: center;
    background-color: $bg1;
    border-top: .5px solid $apple;
    padding: 0;
    border-radius: 0;
    button {
      height: 70px;
      width: 100%;
      margin: 0;
      border-radius: 0;
      border: 2px solid $bg1; } } }


@keyframes pulseNext {
  0% {
    box-shadow: 0 0 0px rgba($green, 0); }
  50% {
    box-shadow: 0 0 20px rgba($green, 1); }
  100% {
    box-shadow: 0 0 0px rgba($green, 0); } }


.advertisementDash {
  background-color: $orange-dark;
  color: rgba(white,.7);
  padding: 10px;
  font-size: 13px;
  text-align: center;
  span {
    color: white;
    font-size: 16px; } }

.link-white {
  font-weight: bold;
  color: white;
  text-decoration: underline; }

.p-relative {
  position: relative; }
.isSubmiting {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(black,.8);
  z-index: 20;
  opacity: 0;
  pointer-events: none;
  &.active {
    pointer-events: auto;
    opacity: 1 !important; } }

@keyframes rotateLogForm {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.stepScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(black,.5);
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  div {
    position: absolute;
    bottom: 15px;
    left: 4vw;
    width: 450px;
    background-color: $apple;
    color: $bg1;
    border-radius: 10px;
    padding: 12px 16px;
    strong {
      color: $green-dark; }
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      right: -7px;
      top: 26px;
      background-color: $apple;
      transform: rotate(45deg); } }
  @media screen and ( max-width: 768px ) {
    div {
      right: 5px;
      bottom: 110px;
      width: calc( 100vw - 70px );
      padding-bottom: 20px;
      &::after {
        right: auto;
        top: calc( 100% - 7px );
        right: 20%; } } }

  &.active {
    opacity: 1;
    pointer-events: auto; } }


.uploadLogo {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 2/1.2;
  overflow: hidden;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }
  img {
    pointer-events: none;
    width: 100%;
    height: 100%; } }


#modalImage,#modalProduct, #modalPost, #modalFavicon {
  .modal-content {
    overflow: hidden;

    .modal-body {
      padding: 0; } }
  .controls {
    position: absolute;
    bottom: 20px;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0px 50px;
    z-index: 1;
    height: 40px;
    display: flex;
    align-items: center;

    span {
      position: absolute;
      display: block;
      text-align: center;
      color: rgba(white,.6);
      pointer-events: none;
      text-shadow: 0 0 10px black;
      font-size: 14px;
      width: 100%;
      left: 0;
      bottom: 35px; } } }

.crop-container {
  bottom: 80px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1; }







.slider {
  padding: 22px 0px; }


.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid $bg1;
  border-bottom: 5px solid $bg1;
  background: transparent;
  width: 100%;
  border-radius: 30px; }



.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: .5px solid $green;
  background: $green;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }


.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: .5px solid $green;
  background: $green;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }


.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%; }


.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16); }

.uploadBtnDash {
  display: flex;
  justify-content: center;
  align-items: center;
  border: .5px solid $apple;
  background-color: $bg1;
  color: $apple;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba($apple,0);
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background: url('../img/dashboard/upload_icon_dash.svg') center / contain no-repeat; }
  &:hover {
    box-shadow: 0 4px 10px rgba($apple,.4); } }

.inputTime {
  font-size: 15px !important;
  padding: 8px !important; }

.iconTitle {
  display: inline-flex;
  align-items: center;
  &::before {
    content: '';
    width: 22px;
    height: 22px;
    margin-right: 8px; }
  &.Facebook {
    &::before {
      background: url('../img/dashboard/social_facebook.svg') center / contain no-repeat; } }
  &.Instagram {
    &::before {
      background: url('../img/dashboard/social_instagram.svg') center / contain no-repeat; } }
  &.TikTok {
    &::before {
      background: url('../img/dashboard/social_tiktok.svg') center / contain no-repeat; } }
  &.YouTube {
    &::before {
      background: url('../img/dashboard/social_youtube.svg') center / contain no-repeat; } }
  &.Pinterest {
    &::before {
      background: url('../img/dashboard/social_pinterest.svg') center / contain no-repeat; } } }

.loaderDash {
  border-radius: 10px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .check {
      width: calc( 100% - 60px );
      height: 25px;
      background-color: white;
      color: $bg2;
      position: absolute;
      bottom: 10px;
      left: 30px;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      &::before {
        content: 'Elegir';
        color: $bg2;
        font-size: 13px; }
      @media screen and ( max-width: 768px ) {
        width: 100%;
        left: 0;
        bottom: 0;
        border-radius: 10px; } }




    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      &:checked + .check {
        background-color: $green;
        &::before {
          content: 'Seleccionado'; } } } } }

.slideClient {
  >div {
    min-height: 200px; } }


.changePassword {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 20;
  background-color: $bg1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  .content {
    width: 750px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgba(black,.1);
    border: .5px solid rgba($apple,.1);
    .logo {
      width: 120px;
      margin-bottom: 30px; }
    label {
      display: block;
      text-align: start;
      margin-bottom: 10px; }
    .inputLanding {
      border: .5px solid white;
      color: white;
      &::placeholder {
        color: white; } }
    .inputPass {
      border: .5px solid white;
      input {
        color: white; }
      button {
        background: url('../img/vector/visible_pass_white.svg') center / 30px no-repeat;
        &.hide {
          background: url('../img/vector/hide_pass_white.svg') center / 30px no-repeat; } } }
    .errorDash {
      justify-content: flex-start;
      text-align: start;
      width: 100%;
      transform: translateY(-10px); }
    .btnGreen {
      color: black;
      height: 55px;
      &:hover {
        background-color: $green-light; } } }
  @media screen and ( max-width: 768px ) {
    padding: 20px 0;
    .content {
      border: none;
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      .logo {
        width: 120px; } } } }


.listStrongPassword {
  display: flex;
  position: relative;
  span {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    padding: 3px 0;
    margin-bottom: 7px;
    &::before {
      content: '';
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 4px;
      background: url('../img/vector/denied.svg') center / contain no-repeat;
      margin-right: 6px; }
    &.active {
      &::before {
        background: url('../img/vector/allow.svg') center / contain no-repeat; } } } }


.tabsSettings {
  margin-top: 20px;
  .nav-tabs {
    border-bottom: .5px solid $green;
    display: flex;
    justify-content: center;
    .nav-item {
      padding: 0;
      margin: 0 3px 1px 3px;
      button {
        padding:  6px 20px;
        background-color: $bg1;
        color: white;
        border: .5px solid $green;
        border-bottom: none;
        &.active {
          background-color: $green;
          color: $bg1; } } } }
  @media screen and ( max-width: 768px ) {
    margin-top: 0;
    overflow-x: auto;
    border-bottom: .5px solid $green;
    .nav-tabs {
      flex-wrap: nowrap;
      width: fit-content;
      .nav-item {
        margin: 0;
        button {
          min-width: 100px;
          border-radius: 0;
          white-space: nowrap;
          height: 55px; } } } } }

.cropping {
  width: 100%;
  height: 100%;
  border: 2px dashed #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  cursor: pointer; }

.cardVisitCounter {
  background-color: $bg1;
  height: 127px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  @media screen and ( max-width: 768px ) {
    height: auto;
    padding: 20px;
    h3 {
      border: .5px dashed $aqua;
      width: 100%;
      border-radius: 8px;
      margin-top: 10px !important;
      padding: 10px; } } }


.faviconField {
  padding: 10px 0;
  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3f3f3fff;
    color: white;
    padding: 10px 20px;
    border-radius: 15px 15px 0 0;
    border: .5px solid rgba(white,.2);
    border-bottom: none;
    div {
      display: flex;
      align-items: center;
      img {
        width: 27px;
        height: 27px;
        object-fit: cover; }
      span {
        margin-left: 10px;
        max-width: 150px; } } }
  .close {
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
      content: '';
      width: 16px;
      height: 2px;
      background-color: white;
      position: absolute; }
    &::before {
      transform: rotate(-45deg); }
    &::after {
      transform: rotate(45deg); } } }


.previewEngine {
  background: white;
  color: rgba(black,.7);
  padding: 30px;
  border-radius: 12px;
  div {
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background: url('../img/vector/planet_link.svg') center / contain no-repeat;
      margin-right: 5px; } }

  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    color: #1a0dab;
    margin: 10px 0; }
  p {
    margin: 0;
    font-size: 17px; } }

.closedAdvertisement {
  background-color: $guide;
  color: white;
  padding: 10px;
  text-align: center;
  div {
    border: .5px dotted rgba(white,.3);
    border-radius: 10px;
    padding: 20px; } }





.toggle-border {
  border: 2px solid #f0ebeb;
  border-radius: 130px;
  margin-bottom: 45px;
  padding: 1px 2px;
  background: linear-gradient(to bottom right, white, rgba(220,220,220,.5)), white;
  box-shadow: 0 0 0 2px #fbfbfb;
  cursor: pointer;
  display: flex;
  align-items: center; }


.toggle-border:last-child {
  margin-bottom: 0; }


.toggle-border input[type="checkbox"] {
  display: none; }


.toggle-border label {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 20px;
  background: #c5063f;
  border-radius: 80px;
  cursor: pointer;
  box-shadow: inset 0 0 16px rgba(0,0,0,.3);
  transition: background .5s; }


.toggle-border input[type="checkbox"]:checked + label {
  background: #0ef5b0; }


.handle {
  position: absolute;
  top: -8px;
  left: -10px;
  width: 35px;
  height: 35px;
  border: .5px solid #e5e5e5;
  background: repeating-radial-gradient(circle at 50% 50%, rgba(200,200,200,.2) 0%, rgba(200,200,200,.2) 2%, transparent 2%, transparent 3%, rgba(200,200,200,.2) 3%, transparent 3%), conic-gradient(white 0%, silver 10%, white 35%, silver 45%, white 60%, silver 70%, white 80%, silver 95%, white 100%);
  border-radius: 50%;
  box-shadow: 3px 5px 10px 0 rgba(0,0,0,.4);
  transition: left .4s; }


.toggle-border input[type="checkbox"]:checked + label > .handle {
  left: calc(100% - 35px + 10px); }


.spinnerAdmin {
  width: 44px;
  height: 44px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d; }


.spinnerAdmin > div {
  background-color: black;
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2px solid $green; }


.spinnerAdmin div:nth-of-type(1) {
  transform: translateZ(-22px) rotateY(180deg); }


.spinnerAdmin div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right; }


.spinnerAdmin div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left; }


.spinnerAdmin div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center; }


.spinnerAdmin div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center; }


.spinnerAdmin div:nth-of-type(6) {
  transform: translateZ(22px); }


@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg); }


  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg); }


  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg); } }


