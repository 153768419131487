.thankPage {
  background-color: #e5e5e5;
  min-height: 100vh;
  padding-bottom: 40px;
  .logo {
    width: 340px;
    @media screen and ( max-width: 768px ) {
      width: 250px; } } }

.iconSuccess {
  width: 60px;
  margin-right: 10px; }

.link-here {
  font-family: $kanti;
  display: inline-flex;
  padding: 0 5px;
  text-decoration: underline;
  color: $guide; }

.popupCredits {
  width: 100vw;
  height: 100vh;
  background-color: rgba(black,.7);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    background-color: $guide;
    color: white;
    max-width: 650px;
    border-radius: 24px;
    padding: 10px;
    position: relative;
    .closeModal {
      position: absolute;
      top: -60px;
      right: 0px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid white;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before, &:after {
        content: '';
        width: 20px;
        height: 3px;
        background-color: white;
        position: absolute;
        transition: all .2s; }
      &::before {
        transform: rotate(-45deg); }
      &::after {
        transform: rotate(45deg); }
      &:hover {
        &::before, &:after {
          transform: rotate(0deg); } } }

    .credit {
      width: 90px; } }
  &.error {
    .content {
      background: linear-gradient(90deg, #fae8c8, white);
      color: black;
      h2 {
        color: $alert;
        margin-bottom: 30px;
        font-size: 50px; } } }
  @media screen and ( max-width: 768px ) {
    .content {
      width: calc( 100vw - 40px );
      .cat {
        width: 70%;
        margin-top: -100px; }
      .credit {
        margin-right: 20px; }
      .btnWhite {
        height: 60px;
        font-size: 25px; } } } }

