.addTime {
  background-color: $guide;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 12px;
  border-radius: 8px;
  margin-top: 10px;
  &::before {
    content: '';
    width: 30px;
    height: 22px;
    margin-right: 6px;
    background: url('../img/dashboard/coin.svg') center / contain no-repeat; }
  @media screen and ( max-width: 768px ) {
    width: 100%;
    height: 70px;
    font-size: 17px;
    &::before {
      width: 40px;
      height: 40px; } } }



.btnVideo {
  display: inline-flex;
  align-items: center;
  width: auto;
  padding: 8px 15px;
  border-radius: 8px;
  background-color: $guide;
  color: white;
  border: .5px solid $guide;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background: url('../img/vector/icon_video.svg') center / contain no-repeat; }
  &:hover {
    background-color: $gold-dark;
    border: .5px solid white; }
  &.active {
    transform: none !important;
    border-top: .5px solid white;
    border-right: .5px solid white;
    border-bottom: .5px solid $gold;
    border-left: .5px solid $gold;
    animation: blurLink 2s linear infinite; } }

.listItems {
  display: flex;
  align-items: center;
  padding: 6px 15px 6px 6px;
  border-radius: 6px;
  background-color: $apple;
  margin-bottom: 10px;
  color: $bg1;
  width: fit-content;
  strong {
    background-color: $bg1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    color: white; }
  span {
    display: block;
    font-size: 14px; } }

.inactiveCredits {
  color: $orange-light;
  border: .5px solid $orange;
  box-shadow: 0 0 12px 3px rgba($orange,.0);
  animation: timmingLightDisabled 3s ease-in-out infinite;
  background-color: $bg1 !important;
  span {
    font-size: 13px; }
  .addTime {
    background-color: $orange-dark;
    width: 100%;
    justify-content: center;
    color: white;
    font-family: $titleDash;
    &::before {
      display: none; } } }

@keyframes timmingLightDisabled {
  0% {
    box-shadow: 0 0 12px 3px rgba($orange,0); }
  50% {
    box-shadow: 0 0 12px 3px rgba($orange, 1); }
  100% {
    box-shadow: 0 0 12px 3px rgba($orange, 0); } }

.circularBARSection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .total {
    position: absolute;
    font-family: $titleDash;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(4px);
    .text-green {
      font-size: 18px;
      margin-right: 5px; } } }

.postSectionNovelties {
  display: flex;
  button {
    display: flex;
    width: 100%;
    margin: 0 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: .5px dashed  rgba($apple,.4);
    border-radius: 10px;
    img {
      width: 90px;
      height: auto; }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $green;
      width: 100%;
      height: 30px;
      color: $bg1;
      border-radius: 7px;
      margin-top: 10px;
      transition: all .2s; }
    &:hover {
      background: $bg1;
      border: .5px dashed  rgba(white,1);
      span {
        background-color: $orange;
        color: white; } } } }

.activePlan {
  display: inline-flex;
  background: $guide;
  border: .5px solid $guide;
  padding: 8px 15px;
  border-radius: 40px;
  color: white;
  font-family: $titleDash;
  &::before {
    content: '';
    width: 23px;
    height: 23px;
    margin-right: 5px;
    transition: transform .3s ease-in-out;
    background: url('../img/dashboard/icon_update.svg') center / contain no-repeat; }
  &:hover {
    &::before {
      transform: rotate(90deg); } } }


.btnClose {
  position: absolute;
  top: -1px;
  right: -1px;
  left: auto;
  background: $green-dark2;
  padding: 10px 30px;
  color: white; }



.sliderNovelties {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 2/1.35;
  overflow: hidden;
  pointer-events: none;
  .slidesN {
    transform: translateY(10%);
    width: calc( 100% - 65px );
    margin: 0 auto; }
  .letters {
    position: absolute;
    z-index: 1;
    top: 1%;
    animation: rotating 75s linear infinite;
    border-radius: 50%;
    opacity: .3;
    @media screen and ( max-width: 768px ) {
      top: -2%; } } }


@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1.06); }

  100% {
    transform: rotate(360deg) scale(1.06); } }




.paymentModalDash {
  padding: 0;
  .modal-dialog {
    min-width: 100vw;
    margin: 0 !important;
    .modal-content {
      min-height: 100vh;
      background: rgba($bg2,.6);
      backdrop-filter: blur(10px);
      border-radius: 0 !important;

      .modal-body {
        .darkPrice {
          background: $bg1;
          border-radius: 40px;
          color: rgba(white,.7);
          position: relative;
          .btnClose {
            position: absolute;
            top: -60px;
            left: -30px;
            display: inline-flex;
            align-items: center;
            background-color: transparent;
            font-family: $nl;
            font-size: 17px;
            width: fit-content;
            &::before {
              content: '';
              width: 50px;
              height: 20px;
              margin-right: 10px;
              background: url('../img/vector/arrow_prev_white.svg') center / contain no-repeat; }
            @media screen and ( max-width: 768px ) {
              position: relative;
              top: 0;
              left: 0;
              margin-top: 20px; } }


          .info {
            padding: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .top {
              h3 {}
              .plan {
                display: inline-flex;
                padding: 5px 10px;
                border: .5px solid rgba($apple, .3);
                border-radius: 8px;
                letter-spacing: 2px;
                margin-bottom: 20px;
                margin-top: 6px;
                color: white; }
              ul {
                padding: 0;
                margin-bottom: 20px;
                li {
                  list-style: none;
                  display: flex;
                  align-items: center;
                  color: white;
                  &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: block;
                    margin-right: 6px;
                    background: url('../img/vector/checked.svg') center / 60% no-repeat; } } } }

            .terms {
              font-size: 14px;
              a {
                color: white;
                text-decoration: underline; } } } } } } } }

.textPlans {
  font-size: 40px;
  background: -webkit-linear-gradient(45deg, $aqua, $guide);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }



.switch-container {
  position: relative;
  width: 122px;
  height: 42px;
  background: $bg1;
  border-radius: 50px;
  border: .5px solid rgba(white,.2); }

.toggle-checkbox {
  display: none; }


.switch {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer; }


.toggle {
  position: absolute;
  width: 50px;
  height: 30px;
  background: $bg2;
  border-radius: 50px;
  top: 5px;
  left: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px; }


.led {
  width: 10px;
  height: 10px;
  background: grey;
  border-radius: 50%;
  transition: all 0.3s ease-in-out; }

.toggle-checkbox:checked + .switch .toggle {
  left: 65px;
  background: $guide; }


.toggle-checkbox:checked + .switch .led {
  background: white; }


.discountLabel {
  display: inline-flex;
  color: white;
  padding: 0 3px;
  border-radius: 4px;
  background-color: $guide; }


.priceCard {
  padding: 5px;
  position: relative;
  z-index: 2;
  background: linear-gradient(to right, $aqua, $guide);
  border-radius: 37px;
  height: 100%;
  .bg {
    border-radius: 34px;
    background: $bg1;
    padding: 30px;
    height: 100%;
    .discount {
      margin: 0;
      display: block;
      width: fit-content;
      font-size: 17px;
      color: white;
      letter-spacing: 2px;
      border-radius: 7px;
      border: .5px solid rgba(white,.4);
      padding: 6px 15px; }

    .prices {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px 0;
      letter-spacing: 1px;
      h3 {
        font-size: 34px;
        color: white; }

      div {
        font-size: 34px;
        font-family: $nl;
        color: gray;
        span {
          display: inline-flex;
          text-decoration: line-through; } } }
    .item {
      font-family: $nl;
      color: white;
      display: inline-flex;
      align-items: center;
      &::before {
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 7px;
        background: url('../img/vector/checked_prices.svg') center / contain no-repeat; } }

    .contentBtn {
      width: 100%;
      height: auto;
      aspect-ratio: 2/.3;
      margin-top: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .buy {
        width: 80%;
        height: 100%;
        color: white;
        background: $guide;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        box-shadow: 3px 3px 0 1px #45397c;
        letter-spacing: 1px;
        pointer-events: none;
        position: absolute;
        z-index: 1; } } }

  &.white {
    background: white;
    .bg {
      background-color: white;
      .discount {
        border: .5px solid black;
        color: black; }
      .prices {
        h3 {
          color: black; } }
      .item {
        color: black; }
      .contentBtn {
        .buy {
          background-color: black;
          box-shadow: 3px 3px 0 1px gray; } } } } }



.progress {
  background-color: rgba(white,.2);
  .progress-bar {
    background-color: $aqua;
    color: $bg1;
    min-width: 40px;
    strong {
      font-size: 12px; } } }

.licenceDays {
  color: $apple;
  margin: 0;
  display: inline-flex;
  width: fit-content;
  border: .5px dotted rgba($apple,.3);
  border-radius: 8px;
  padding: 3px 10px;
  font-family: $nl !important;
  letter-spacing: 1px; }
