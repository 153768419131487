
$greenOrder: #71f9cb;

.orderPage {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(black,.05);
  box-shadow: 0 10px 0px rgba(black,.2);
  padding: 40px;
  overflow-x: hidden;
  .bg-light {
    background: rgba($gray,.15) !important; }
  @media screen and ( max-width: 768px ) {
    padding: 20px; } }

.btnLink {
  text-decoration: underline;
  color: black;
  &:hover {
    color: black;
    background-color: #ededed; } }

.loadOrder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0d0d0;
  img {
    width: 40px;
    filter: brightness(100); } }


.tableOrder {
  background: rgba(black,.05);
  border-radius: 10px;
  padding: 15px;
  table {
    width: 100%;
    font-size: 14px;
    tbody {
      tr {
        border-bottom: .5px dashed #a4b7b9;
        td {
          padding: 8px 0;
          &:nth-child(2) {
            text-align: end;
            strong {
              font-size: 14px; } } } } } }
  @media screen and ( max-width: 768px ) {
    height: auto !important;
    margin-bottom: 15px; } }




.cardListOrder {
  background-color: white;
  border-radius: 14px;
  margin-bottom: 20px;
  .principal {
    display: flex;
    padding: 10px;
    img {
      width: 100px;
      height: 80px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 8px; }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      div {
        h6 {
          font-size: 15px;
          margin-bottom: 7px; }
        p {
          margin: 0;
          font-size: 13px; } }
      strong.after {
        font-size: 13px; } } }
  .complement {
    margin-top: 8px;
    padding: 8px;
    border-top: .5px dashed rgba(black,.2);
    p {
      margin: 0;
      font-size: 14px; } } }


.orderState {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  div {
    width: 100%;
    text-align: center;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      width: 100%;
      padding: 10px 20px;
      border-radius: 7px;
      color: gray;
      box-shadow: 0 0 10px rgba(black,.05);
      margin-bottom: 20px;
      &.checked {
        box-shadow: 0 0 10px rgba($greenOrder, .2); }
      &.current {
        background-color: $greenOrder;
        color: black; }
      &.disabled {
        background-color: rgba(black,.05);
        box-shadow: none; } }
    span {
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      &.checked {
        background: $greenOrder url('../img/dashboard/check_step.svg') center / 25px no-repeat; }
      &.current {
        background: $greenOrder url('../img/dashboard/current_step.svg') center / 25px no-repeat;
        animation: pointerOrder 3.3s ease-in-out infinite; }
      &.disabled {
        background: rgba(black,.1); } } }

  .line {
    position: absolute;
    width: 100%;
    height: 7px;
    bottom: 20px;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 12.5%;
    div {
      width: 15%;
      height: 100%;
      margin: 0 10px;
      border-radius: 10px;
      background: rgba(black,.1);
      &.checked {
        background-color: $greenOrder; } } }
  @media screen and ( max-width: 768px ) {
    display: block;
    .line {
      display: none; }
    div {
      flex-direction: row-reverse;
      margin-bottom: 10px;
      span {
        margin-right: 30px; }
      p {
        margin: 0; } } } }





@keyframes pointerOrder {
  0% {
    box-shadow: 0 0 0 0px rgba( $greenOrder, .4); }
  70% {
    box-shadow: 0 0 0 30px rgba( $greenOrder, 0); }
  100% {
    box-shadow: 0 0 0 30px rgba( $greenOrder, 0); } }


.btnContact {
  background-color: $greenOrder;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  text-decoration: none;
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: url('../img/dashboard/icon_wsp_black.svg') center / 27px no-repeat; }
  &:hover {
    color: white;
    background-color: black;
    &::before {
      background: url('../img/dashboard/icon_whatsapp.svg') center / 27px no-repeat; } } }


.errorOrder {
  display: flex;
  border-radius: 10px;
  padding: 20px;
  background-color: #feeded;
  img {
    width: 60px;
    margin-right: 20px; }
  @media screen and ( max-width: 768px ) {
    display: block;
    text-align: center;
    img {
      margin-bottom: 20px; } } }

