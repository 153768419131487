@font-face {
	font-family: 'regular';
	src: url('./fonts/regular.ttf') format("truetype");
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'title';
	src: url('./fonts/Chapaza.ttf') format("truetype");
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'titleDash';
	src: url('./fonts/Ubuntu-Medium.ttf') format("truetype");
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'roboto';
	src: url('./fonts/Roboto-Regular.ttf') format("truetype");
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'kanti';
	src: url('./fonts/Kanit-SemiBold.ttf') format("truetype"); }
@font-face {
	font-family: 'nunitoLight';
	src: url('./fonts/Nunito-Light.ttf') format("truetype"); }


//--------
$regular: 'regular';
$roboto: 'roboto';
$title: 'title';
$titleDash: 'titleDash';
$kanti: 'kanti';
$nl: 'nunitoLight';
//--------
$orange-light: #f8f6f1;
$green-light: #e7f9cf;
$orange: #f2864c;
$orange-dark: #ce5f23;
$pink: #FFCEB8;
$error: #6f1e46;
$dark-purple: #322d47;
$green-dark: #def6b4;
$green-dark2: #576839;
$green2: #919f7f;
$green: #e2eed1;
$dark: #453f3c;
$gray-dark: #4c4b56;
$gray: #c5c5c8;
$gold: #e7b557;
$gold-dark: #9b823e;
$purple: #c0a9f7;
$aqua: #8bf4ac;
$guide: #734be3;
$alert: #c22a5f;

html {
	scroll-behavior: smooth; }
body {
	color: $dark;
	font-size: 15px;
	font-family: $roboto;
	background: rgba($orange-light,.7);
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px; }
	&::-webkit-scrollbar-track {
		background-color: black;
		border-radius: 10px; }
	&::-webkit-scrollbar-thumb {
		background-color: white;
		border-radius: 10px; } }

::selection {
	background-color: $aqua;
	color: black; }
p {
	font-size: 14px; }
a {
	text-decoration: none; }
a,button,input,select,textarea {
	transition: all .3s;
	color: $dark; }

button {
	border: none;
	background-color: transparent; }

h1, h2, h3, h4, h5, h6, strong {
	font-family: $titleDash; }

strong {
	font-size: 15px; }

::placeholder {
	color: $dark;
	font-size: 13px; }
input, button, textarea, select {
	&:focus {
		outline: none; } }

section {
	overflow-x: hidden; }

.icon {
	width: 22px;
	height: 22px;
	object-fit: contain; }

.btn-green {
	display: inline-flex;
	background: linear-gradient(90deg, $green2,$green);
	color: $orange-light;
	height: 35px;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	border-radius: 30px;
	font-size: 14px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&::before {
		content: '';
		width: 20px;
		height: 20px;
		margin-right: 10px;
		background: url('../img/vector/user.svg') no-repeat; }
	&::after {
		content: '';
		width: calc( 100% - 4px );
		height: calc( 100% - 4px );
		border: .5px dotted rgba($orange-light,.4);
		top: 2;
		left: 2;
		position: absolute;
		border-radius: 40px; }

	&:hover {
		background-color: $green;
		color: white; } }

.text-green {
	color: $green; }
.text-dark-green {
	color: $green-dark; }
.text-green-light {
	color: $green-light; }
.text-green2 {
	color: $green2; }
.text-orange {
	color: $orange; }
.text-pink {
	color: $pink; }
.text-gray {
	color: $gray; }
.text-light, .text-white {
	color: $orange-light !important; }
.text-dark {
	color: $dark; }
.text-purple {
	color: $purple; }
.text-aqua {
	color: $aqua; }

.bg-green {
	background-color:  $green; }
.bg-dark-green {
	background-color:  $green-dark; }
.bg-green-light {
	background-color:  $green-light; }
.bg-green2 {
	background-color:  $green2; }
.bg-orange {
	background-color:  $orange; }
.bg-pink {
	background-color:  $pink; }
.bg-gray {
	background-color:  $gray; }
.bg-dark-purple {
	background-color:  $dark-purple; }
.bg-light {
	background-color:  $orange-light !important; }
.bg-dark {
	background-color:  $dark !important; }
.bg-gray-dark {
	background-color:  $gray-dark !important; }

.text-gray2 {
	color: gray; }
.text-greendark {
	color: $green-dark2; }
.text-orange-dark {
	color: $orange-dark; }
.text-link {
	height: 50px;
	width: 100%;
	display: block;
	text-decoration: underline;
	color: white;
	text-align: center;
	&:hover {
		color: $green; } }

.text-link-apple {
	color: $green;
	&:hover {
		color: $green-dark; } }

.dashLabel {
	color: white;
	span {
		color: $orange; } }

.requiredLabel, .optionalLabel {
	display: inline-flex;
	padding: 2px 6px;
	font-size: 12px;
	border-radius: 4px; }
.requiredLabel {
	color: white;
	background-color: $orange; }
.optionalLabel {
	color: black;
	background-color: $green; }

.btnWhite {
	background-color: white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 40px;
	border: .5px solid white;
	color: black;
	padding: 8px 20px;
	&:hover {
		background-color: $gold;
		border: .5px solid $gold; } }

.border-none {
	border: none; }

$lg : #eae8e8;
$ad : #89f9b5;
$purple2: #6721b7;
$textLanding: #394056;


.inputCheck {
	padding-left: 35px;
	position: relative;
	input {
		display: none;
		&:checked ~span {
			&::before {
				border: .5px solid black;
				background: black url('../img/vector/checked.svg') center / 12px no-repeat; } } }

	span {
		display: block;
		align-items: center;
		font-size: 12px;
		color: black;
		letter-spacing: 1px;
		&::before {
			content: '';
			width: 23px;
			height: 23px;
			border: .5px solid black;
			border-radius: 4px;
			position: absolute;
			left: 0;
			cursor: pointer;
			transition: all .2s;
			background: transparent url('../img/vector/checked.svg') center / 0px no-repeat; } }
	a {
		margin: 0;
		color: black;
		letter-spacing: 1px;
		text-decoration: underline;
		margin: 0;
		&:hover {
			color: $dark-purple; } } }

.tab-pane, .nav-link {
	&:focus-visible {
		border: none;
		outline: none;
		box-shadow: none; } }
