.inputSocial {
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(black,.2);
  .headSocial {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-switch {
      input {
        width: 70px;
        height: 30px; } } }
  .content {
    border-top: .5px solid $w40;
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      width: 100%;
      height: 45px;
      background-color: $bg1;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 0 20px;
      &::placeholder {
        color: rgba(white,.7); } }
    button,a {
      width: 45px;
      height: 45px;
      min-width: 45px;
      min-height: 45px;
      margin-left: 5px;
      border-radius: 8px;
      display: block;
      &.link {
        background: $bg1 url('../img/dashboard/icon_link.svg') center / 30px no-repeat; }
      &.delete {
        background: $bg1 url('../img/dashboard/icon_delete.svg') center / 40px no-repeat; } } }
  @media screen and ( max-width: 768px ) {
    padding: 10px; } }

