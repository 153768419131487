.courtain {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc( 100vw - 210px );
  height: calc( 100vh - 50px );
  background: linear-gradient(180deg,rgba(black,.6),rgba(black,0));
  z-index: 2;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  .pointer {
    position: fixed;
    background-color: $guide;
    color: white;
    display: inline-flex;
    justify-content: center;
    max-width: 350px;
    padding: 10px 20px;
    border-radius: 6px;
    animation: bucleLeft 2.5s ease-in-out infinite;
    strong {
      white-space: nowrap;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      height: 20px;
      border-radius: 4px;
      padding: 4px 7px; }
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: $guide;
      position: absolute;
      top: -5px;
      left: 60px;
      transform: rotate(45deg); }
    &.point1, &.step6 {
      top: 20px;
      left: 20px;
      animation: bucleUp 2.5s ease-in-out infinite; }
    &.step2 {
      left: 20px;
      top: 55px;
      animation: bucleLeft 2.5s ease-in-out infinite;
      &::before {
        top: 15px;
        left: -5px; } }
    &.step3 {
      left: 20px;
      top: 100px;
      animation: bucleLeft 2.5s ease-in-out infinite;
      &::before {
        top: 15px;
        left: -5px; } }
    &.step4 {
      left: 20px;
      top: 137px;
      animation: bucleLeft 2.5s ease-in-out infinite;
      &::before {
        top: 15px;
        left: -5px; } } }

  @media screen and ( max-width: 768px ) {
    width: calc( 100vw - 60px );
    height: 100%;
    top: 60px;
    padding: 15px;
    .pointer {
      width: 95%;
      &.point1, &.step6 {
        top: 20px;
        left: 10px; }
      &.step2 {
        top: 65px;
        max-width: 270px; }
      &.step3 {
        top: 115px;
        max-width: 270px; }
      &.step4 {
        top: 165px;
        max-width: 270px; } } } }

.add-purple {
  background-color: $guide;
  text-align: center;
  border-radius: 10px;
  color: white;
  padding: 10px;
  h6 {
    strong {
      display: inline-flex;
      padding: 4px 10px;
      background-color: black;
      color: white;
      margin-right: 5px;
      border-radius: 4px; } }
  p {} }


@keyframes bucleUp {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(6px); }
  100% {
    transform: translateY(0px); } }

@keyframes bucleLeft {
  0% {
    transform: translateX(0px); }
  50% {
    transform: translateX(6px); }
  100% {
    transform: translateX(0px); } }


.bright {
  animation: brigthness 3s ease-in-out infinite;
  border: .5px solid $guide;
  input {
    &::placeholder {
      color: white; } } }
.brightness {
  padding: 30px;
  border-radius: 10px;
  border: .5px solid $guide;
  animation: brigthness 3s ease-in-out infinite; }

@keyframes brigthness {
  0% {
    box-shadow: 0 0 0px rgba($guide,0); }
  50% {
    box-shadow: 0 0 55px rgba($guide,.6); }
  100% {
    box-shadow: 0 0 0px rgba($guide,0); } }


.pointTheme {
  position: relative;
  &::after {
    content: 'Paso 3';
    width: 40px;
    font-size: 10px;
    border-radius: 3px;
    background-color: $green;
    pointer-events: none;
    position: absolute;
    right: 5px;
    color: $bg1; }
  @media screen and ( max-width: 768px ) {
    &::after {
      bottom: -3px; } } }

